import React from 'react';
import PropTypes from 'prop-types';
import CropContent from 'components/crop-content/CropContent';
import InfoEvent from '../InfoEvent';
import { useSelector } from 'react-redux';

const WriterApplied = ({ attaches, type }) => {
	const id = useSelector(state => state.auth.user?.id);

	if (attaches[0].object.writer_id !== id) {
		return null;
	}

	return (
		<InfoEvent
			attaches={attaches}
			type={type}
			layout={
				attaches[0].object.comment && (
					<div className='activity-info'>
						<div className='activity-issue pt-3'>
							<div className='writer-statistic'>
								<CropContent
									contents={attaches[0].object.comment}
								/>
							</div>
						</div>
					</div>
				)
			}
		/>
	);
};

WriterApplied.propTypes = {
	attaches: PropTypes.array,
	type: PropTypes.string
};

WriterApplied.defaultProps = {
	attaches: []
};

export default WriterApplied;
