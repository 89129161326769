import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

function PrivateRoute({ children }) {
	const { token, isExpired } = useSelector(state => state.auth);
	const location = useLocation();

	if (!token) {
		return <Navigate to={isExpired ? `/login?callback-url=${location?.pathname}${location?.search}` : '/login'}/>;
	}
	return children;
}
PrivateRoute.propTypes = {
	children: PropTypes.any,
	roles: PropTypes.array
};

export default PrivateRoute;
