import React, { useEffect, useRef, useState } from 'react';
import './Style.scss';
import btnSend from 'assets/images/message/btn-send-default.svg';
import btnSendActive from 'assets/images/message/btn-send-active.svg';
import { sendOrderMessage } from 'store/thunks';
import { autoScrollWhenSendMessage, updateTimeMark } from 'store/slices/message';
import { useDispatch, useSelector } from 'react-redux';
import { ORDER_STATUSES } from 'constants/orders-status';

function ChatForm() {
    const [formValue, setFormValue] = useState('');
    const [isFocus, setIsFocus] = useState(false);
    const dispatch = useDispatch();
    const formRef = useRef();
    const { messageInfo: { order } } = useSelector((state) => state.message);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setFormValue(newValue);
        changeStyleHeightForm();
    };

    const changeStyleHeightForm = (newHeight) => {
        const messageBox = document.querySelector('.message-form');
        messageBox.style.height = 'auto';
        messageBox.style.height = newHeight || messageBox.scrollHeight + 'px';
    };

    const handleKeyPress = (e) => {
        const { ctrlKey, shiftKey, key } = e;

        if (key === 'Enter') {
            if (!ctrlKey && !shiftKey) {
                handleSendMessage();
                e.preventDefault();
            }
        }
    };

    const handleSendMessage = () => {
        const messageContent = formValue.trim();

        if (!getActiveButtonSend() || !messageContent) {
            return;
        }
        dispatch(autoScrollWhenSendMessage());
        dispatch(sendOrderMessage({
            orderId: order?.id,
            data: {
                content: messageContent
            }
        }));
        setFormValue('');
        changeStyleHeightForm(21);
    };

    const handleFocus = () => {
        setIsFocus(true);
        dispatch(updateTimeMark());
        if (formRef.current) {
            formRef.current.focus();
        }
    };

    const getActiveButtonSend = () => {
        const orderStatus = order?.status;

        if (!orderStatus || ORDER_STATUSES.REFUNDED === orderStatus) {
            return false;
        }

        return formValue.trim();
    };

    const checkDisableTextForm = () => {
        if (!order) {
            return true;
        }
        const { status, messagable } = order;
       return status === ORDER_STATUSES.REFUNDED || !messagable;
    };

    useEffect(() => {
        if (!checkDisableTextForm()) {
            setIsFocus(true);
            formRef?.current?.focus();
        }
    }, [order]);

    return (
        <div className='chat-form'>
            <div
                onFocus={handleFocus}
                 onBlur={() => setIsFocus(false)}
                 className={`input ${isFocus ? 'focus' : ''}`}
            >
            <textarea
                className='message-form'
                value={formValue}
                onChange={handleChange} rows={1} placeholder='Type a message...'
                onKeyDown={handleKeyPress}
                ref={formRef}
                disabled={checkDisableTextForm()}
            />
            </div>
            <div className={`btn-send ${getActiveButtonSend() ? 'active' : ''}`} onClick={handleSendMessage}>
                <img width={24} height={24} src={getActiveButtonSend() ? btnSendActive : btnSend} alt='Btn send'/>
            </div>
        </div>
    );
}

export default ChatForm;
