import React from 'react';
import PropTypes from 'prop-types';
import InfoEvent from '../InfoEvent';
import Files from "../../../../components/files/Files";
import CropContent from "../../../../components/crop-content/CropContent";

const DisputeStarted = ({ attaches, type }) => {

	return (
		<InfoEvent
			attaches={attaches}
			type={type}
			layout={
				<>
					<div className='activity-info'>
						<Files attaches={attaches} type={type}/>
						<div className='activity-issue pt-3'>
							{
								attaches[0].object.comment && <div className='writer-statistic'>
									<CropContent
										contents={`${attaches[0].object.comment}`}
									/>
								</div>
							}
						</div>

					</div>
				</>
			}
		/>
	);
};
DisputeStarted.propTypes = {
	attaches: PropTypes.array,
	type: PropTypes.string
};

DisputeStarted.defaultProps = {
	attaches: [],
	type: ''
};
export default DisputeStarted;
