import CropContent from 'components/crop-content/CropContent';
import React from 'react';
import PropTypes from 'prop-types';
import InfoEvent from '../InfoEvent';

const FinalizationDeclined = ({ attaches, type }) => {
	return (
		<InfoEvent
			attaches={attaches}
			type={type}
			layout={
				attaches[0]?.object.message && (
					<div className='activity-info'>
						<div className='writer-statistic'>
							<h6 className='fs-6 fw-600'>Writing Service</h6>
							<CropContent
								contents={attaches[0]?.object.message}
							/>
						</div>
					</div>
				)
			}
		/>
	);
};

FinalizationDeclined.propTypes = {
	attaches: PropTypes.array,
	type: PropTypes.string
};

FinalizationDeclined.defaultProps = {
	attaches: []
};
export default FinalizationDeclined;
