import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { keepLinesBreak } from 'utils/formatText';
import './CropContent.scss';

const CropContent = ({ contents }) => {
	const [isActive, setIsActive] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const refContent = useRef();

	useEffect(() => {
		if (refContent?.current?.clientHeight > 160) {
			return setShowMore(true);
		}
	}, [refContent?.current?.clientHeight]);

	return (
		<div className={`paragraph ps-2 ${isActive ? 'active' : ''}`}>
			<div className={`paragraph__content ${showMore && 'overlay'}`}>
				<div className='paragraph__inner' ref={refContent} dangerouslySetInnerHTML={{ __html: keepLinesBreak(contents) }} data-testid='instructions'/>
			</div>
			{showMore && <div className='text-end pb-1'>
				<button className='read-more font-italic text-uppercase border-0 text-decoration-none bg-transparent px-0' onClick={() => {setIsActive(!isActive);}}>{isActive ? 'Show less' : 'Show more' }</button>
			</div>}
		</div>
	);
};
CropContent.propTypes = {
	contents: PropTypes.any
};

export default CropContent;
