import React from 'react';
import PropTypes from 'prop-types';
import './LayoutSignin.scss';
import Logo from 'assets/images/logo.svg';
import { Link } from 'react-router-dom';

const LayoutSignin = ({ children }) => {
	return (
		<div className='sign-in d-flex'>
			<div className='col-lg-8 col-md-7 d-none d-md-flex align-items-center justify-content-center sign-in__left'>
				<Link to='/' className='text-decoration-none'>
					<img className='rounded-circle overflow-hidden bg-white sign-in__logo' width='120' height='120' src={Logo} alt='logo'/>
				</Link>
			</div>
			<div className='col-12 col-lg-4 col-md-5 sign-in__right'>
				{children}
			</div>
		</div>
	);
};
LayoutSignin.propTypes = {
	children: PropTypes.any
};
export default LayoutSignin;
