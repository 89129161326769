import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getPrevMessage,
    getNextMessage,
    sendMessage as sendMessageFirebase,
    getTotalMessageOfOrder as getTotalMessageOfOrderFirebase
} from 'firebase-store/query';
import { messageService } from 'services/modules/message';

const getPrevMessageDetail = createAsyncThunk(
    'message/getPrevMessageDetail',
    async ({ orderSignature, time, limit }, { rejectWithValue }) => {
        try {
            return await getPrevMessage(orderSignature, time, limit);
        } catch (e) {
            console.log(e);
            return rejectWithValue(e.response.data);
        }
    }
);
const getNextMessageDetail = createAsyncThunk(
    'message/getNextMessageDetail',
    async ({ orderSignature, time }, { rejectWithValue }) => {
        try {
            return await getNextMessage(orderSignature, time);
        } catch (e) {
            console.log(e);
            return rejectWithValue(e.response.data);
        }
    }
);

const sendMessage = createAsyncThunk(
    'message/sendMessage',
    async (payload, { rejectWithValue }) => {
        try {
           return await sendMessageFirebase(payload.orderSignature, payload.message);
        } catch (e) {
            console.log(e);
            return rejectWithValue(e.response.data);
        }
    }
);

const sendOrderMessage = createAsyncThunk('message/sendOrderMessage', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await messageService.sendOrderMessage(payload.orderId, payload.data);
        return data.data;
    } catch (e) {
        return rejectWithValue(e.response.data);
    }
});

const markMessageAsRead = createAsyncThunk('message/sendOrderMessage', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await messageService.markMessageAsRead(payload.orderId);
        return data.data;
    } catch (e) {
        return rejectWithValue(e.response.data);
    }
});

const getTotalMessageOfOrder = createAsyncThunk('message/sendOrderMessage', async (payload, { rejectWithValue }) => {
    try {
       return await getTotalMessageOfOrderFirebase(payload);
    } catch (e) {
        return rejectWithValue(e.response.data);
    }
});

const getOrderMessage = createAsyncThunk('message/getOrderMessage', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await messageService.getOrderMessage(payload);
        return data;
    } catch (e) {
        return rejectWithValue(e.response.data);
    }
});

const getOrderMessageWillListen = createAsyncThunk('message/getOrderMessageWillListen', async (_, { rejectWithValue }) => {
    try {
        const { data } = await messageService.getOrderMessageWillListen();
        return data;
    } catch (e) {
        return rejectWithValue(e.response.data);
    }
});

const getTotalUnreadMessage = createAsyncThunk('message/getTotalUnreadMessage', async (_, { rejectWithValue }) => {
    try {
        const { data } = await messageService.getTotalUnreadMessage();
        return data;
    } catch (e) {
        return rejectWithValue(e.response.data);
    }
});

const getCursorMessage = createAsyncThunk('message/getCursorMessage', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await messageService.getCursorMessage(payload);
        return data;
    } catch (e) {
        return rejectWithValue(e.response.data);
    }
});

export {
    sendMessage,
    getPrevMessageDetail,
    getNextMessageDetail,
    sendOrderMessage,
    markMessageAsRead,
    getTotalMessageOfOrder,
    getOrderMessage,
    getOrderMessageWillListen,
    getTotalUnreadMessage,
    getCursorMessage
};
