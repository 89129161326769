import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import useTime from 'hooks/useTime';
import { keepLinesBreak } from 'utils/formatText';
import { useParams } from 'react-router-dom';
import { submitAppliedOrderById } from 'store/thunks';

const WriterApplied = ({ order }) => {
	const { applied, loadingApplied } = useSelector((state) => state.order);
	const { id } = useParams();
	const { getTimeDiff } = useTime();
	const dispatch = useDispatch();

	const defaultValues = {
		comment: ''
	};

	const { register, handleSubmit } = useForm({
		mode: 'onSubmit',
		defaultValues
	});

	const handleSubmitComment = (values) => {
		const body = {
			id: id,
			comment: values.comment
		};
		dispatch(submitAppliedOrderById(body));
	};

	return (
		<>
			{
				order?.is_applied_to ? (
					applied &&
				<>
					<div className='aside__apply-info box-padding d-flex align-items-center justify-content-between font-ubunty'>
						<span className='fw-bold text-uppercase' data-testid='apply-title'>
							You applied
						</span>
						<span data-testid='apply-time'>
							{getTimeDiff(applied?.created_at)}
						</span>
					</div>
					<div className='aside__apply-comment box-padding'>
						<span data-testid='apply-message'
							className='empty h-100 fst-normal text-secondary'
							dangerouslySetInnerHTML={{
								__html: keepLinesBreak(
									`${
										applied?.comment
											? applied?.comment
											: '~ no comment ~'
									}`
								)
							}}
						></span>
					</div>
				</>
				) : (!order?.is_related_to && (
					<form
						className='aside__remark pd-12'
						onSubmit={handleSubmit(handleSubmitComment)}
					>
						<textarea
							data-testid='comment-box'
							rows='4'
							className='w-100 aside__remark__textarea box-border pd-12'
							name='comment'
							id='comment'
							placeholder='If you have any comments or questions, please, place them here.'
							{...register('comment')}
						></textarea>
						<button className='aside__btn btn btn-warning border-0 rounded-3 d-flex align-items-center justify-content-center ms-auto mt-2'
							data-bs-toggle='tooltip'
							title='Apply'
							disabled={loadingApplied}
							data-testid='apply-button'
						>
							<i
								data-testid='icon-apply'
								className={`fa ${
									loadingApplied
										? 'fa-circle-o-notch fa-spin'
										: 'fa-shopping-bag'
								}`}
								aria-hidden='true'
							></i>
						</button>
					</form>
				)
				)
			}
		</>
	);
};

WriterApplied.propTypes = {
	order: PropTypes.object
};

WriterApplied.defaultProps = {
	order: {}
};

export default WriterApplied;
