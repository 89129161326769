import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Header from 'components/header/Header';
import './Layout.scss';
import Message from '../message';
import { useDispatch, useSelector } from 'react-redux';
import { getCursorMessage, getOrderMessageWillListen, getTotalUnreadMessage } from 'store/thunks';
import { receiveGroupMessage } from 'firebase-store/query';
import { listenEventOfMessageNotification, resetAllState, resetStateMessageNotification } from 'store/slices/message';
import { FROM } from 'constants/message';

const Layout = () => {
	const { isShowMessage } = useSelector((state) => state.message);
	const dispatch = useDispatch();
	const listenEvent = () => {
		const subscribes = [];
		dispatch(getOrderMessageWillListen()).unwrap().then((orders) => {
			if (orders.length) {
				orders.forEach((order) => {
					const subscribe = listenEventOneOrder(order);
					subscribes.push(subscribe);
				});
			}
		});

		return subscribes;
	};

	const listenEventOneOrder = (order) => {
		let subscribe = null;
		if (order.signature) {
			subscribe = receiveGroupMessage(order.signature, new Date().getTime(), (message) => {
				const messageNotification = {
					...order, message, messagable: true
				};

				if (message.from !== FROM && isShowMessage) {
					dispatch(getCursorMessage(order.id));
				}
				dispatch(listenEventOfMessageNotification(messageNotification));
			});
		}

		return subscribe;
	};

	useEffect(() => {
		const subscribes = listenEvent();
		dispatch(getTotalUnreadMessage());

		return () => {
			dispatch(resetStateMessageNotification());
			dispatch(resetAllState());

			subscribes.forEach((subscribe) => {
				if (subscribe) {
					subscribe();
				}
			});
		};
	}, []);

	return (
		<div className='l-layout'>
			<Header />
			<main className='l-main main'>
				<div className='layout-wrapper'>
					<div className={`content box-padding ${isShowMessage ? 'show-message' : ''}`}>
						<Outlet/>
					</div>
					<Message/>
				</div>
			</main>
		</div>
	);
};
Layout.propTypes = {
	children: PropTypes.any
};
export default Layout;
