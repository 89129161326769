import React from 'react';
import PropTypes from 'prop-types';
import './OrderPreview.scss';
import useFile from 'hooks/useFile';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { submitAppliedOrderById } from 'store/thunks';
import { keepLinesBreak } from 'utils/formatText';
import { getFileName } from 'utils/formatText';
import downloadLink from 'utils/downloadLink';

const OrderPreview = ({ order }) => {
	const { formatBytes } = useFile();
	const dispatch = useDispatch();
	const { loadingApplied } = useSelector((state) => state.order);
	const { token } = useSelector(state => state.auth);

	const downloadFile = (e, pathLink, pathName) => {
		e.stopPropagation();
		const pathFile = `${process.env.REACT_APP_API_URL}${pathLink}?token=${token}`;
		downloadLink(pathFile, pathName);
	};

	const getDownloadLink = (e, file) => {
		if (file) {
			return downloadFile(e, file.path, file.name);
		}
	};

	const handleApply = () => {
		const body = {
			id: order.id,
			comment: ''
		};
		dispatch(submitAppliedOrderById(body));
	};

	return (
		<tr className='d-none'>
			<td colSpan={7}>
				<div className='order-preview'>
					<p className='order-preview__topic fs-18 mb-3 pb-1'>{order.topic}</p>
					<div className='d-flex'>
						<dl className='order-options order-options__level'>
							<dt className='order-options__dt'>Level</dt>
							<dd className='order-options__dd bg-white'>{order.level}</dd>
						</dl>
						<dl className='order-options order-options__citation'>
							<dt className='order-options__dt'>Citation</dt>
							<dd className='order-options__dd bg-white'>{order.citation_style}</dd>
						</dl>
						<dl className='order-options order-options__spacing'>
							<dt className='order-options__dt'>Spacing</dt>
							<dd className='order-options__dd bg-white'>{order.spacing === 2 ? 'Double' : 'Single'}</dd>
						</dl>
						<dl className='order-options order-options__words'>
							<dt className='order-options__dt'>Words</dt>
							<dd className='order-options__dd bg-white'>{order.spacing === 2 && order.pages_number ? +order.pages_number * 275 : +order.pages_number * 550}</dd>
						</dl>
					</div>
					<ul className='order-preview__files list-unstyled'>
						{order.files && order.files.map((file, idx) => (
							<li className='file-item d-flex align-items-center' key={idx}>
								<i className='file-item__ficon ti-file'></i>
								<span className='file-item__fsize'>
									{formatBytes(file.size)}
								</span>
								<span className='file-item__fname'>
									<a title='filename' onClick={(e) => getDownloadLink(e, file)}>
										{getFileName(file.name, 110)}
									</a>
								</span>
							</li>
						))}
					</ul>
					<p className='order-preview__instructions mb-3 mt-1' dangerouslySetInnerHTML={{ __html: keepLinesBreak(order.preview) }}/>
					<div className='order-preview__buttons d-flex pt-3 mt-1 pb-3'>
						<Link to={`/details/${order.id}`} className='text-white fs-14 text-uppercase text-center btn btn-primary ps-1 pe-3 me-2'>
							<i className='fa fa-info icon--medium me-2' aria-hidden='true' />Details
						</Link>
						<button className='btn btn-warning simple text-white text-uppercase fs-14 text-center ps-1 pe-3' onClick={() => {handleApply();}}>
							<i className={`fa ${loadingApplied ? 'fa-circle-o-notch fa-spin' : 'fa-shopping-bag'} icon--medium me-2`} aria-hidden='true' />Apply
						</button>
					</div>
				</div>
			</td>
		</tr>
	);
};

OrderPreview.propTypes = {
	order: PropTypes.object
};

OrderPreview.defaultProps = {
	order: {}
};

export default OrderPreview;
