import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import routes from 'routes';
import PrivateRoute from 'components/private-route/PrivateRoute';
import Toastr from 'components/toastr/Toastr';
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'assets/css/global.scss';

const App = () => {
	const { toastr } = useSelector((state) => state.toastr);
	const renderRoute = (route, key, renderRoute = () => {
	}) => {
		if (route.navigateTo) {
			return <Route path={route.path} key={key} element={<Navigate to={route.navigateTo} replace />}/>;
		}
		if (route.isPrivate) {
			return (
				<Route
					key={key}
					path={route.path}
					element={
						<PrivateRoute redirectTo='/login'>{route.component}</PrivateRoute>
					}
				>
					{route.children &&
					route.children.map((r, index) => renderRoute(r, `${key}_${index}`, renderRoute))}
				</Route>
			);
		}
		return <Route key={key} path={route.path} element={route.component}/>;
	};

	return (
		<>
			<Toastr toastr={toastr}/>
			<Routes>
				{routes.map((route, index) => renderRoute(route, index, renderRoute))}
			</Routes>
		</>
	);
};

export default App;
