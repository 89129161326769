import React from 'react';
import './Style.scss';
import PropTypes from 'prop-types';

function LoadingMessage({ className }) {
    return <div className={`loading-message-wrapper ${className}`}>
        <div className='loading-message'>
            <div className='box skeleton'></div>
            <div className='info'>
                <div className='name skeleton'></div>
                <div className='msg skeleton'></div>
            </div>
        </div>

        <div className='loading-message'>
            <div className='box skeleton'></div>
            <div className='info'>
                <div className='name skeleton'></div>
                <div className='msg skeleton'></div>
            </div>
        </div>
    </div>;
}

LoadingMessage.propTypes = {
    className: PropTypes.string
};

export default LoadingMessage;
