import React, { useState, useRef, useEffect } from 'react';
import './Notification.scss';
import useOutsideClick from 'hooks/useOutsideClick';
import NotificationItem from './item/Item';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNotifications, clearAllNotifications, getCount } from 'store/thunks';
import { reset } from 'store/slices/notifications';
import useInterval from 'hooks/useInterval';
import { Howl } from 'howler';
import sound from 'assets/audio/bell_ring.mp3';
import Loading from 'components/loading/Loading';
import bellIcon from 'assets/images/bell-icon.svg';

const Notification = () => {
	const dispatch = useDispatch();
	const {
		notifications,
		currentPage,
		hasNextPage,
		total,
		hasNewNotification,
		isClearAll,
		loading,
		loadingClear,
		isItemShown
	} = useSelector(state => state.notification);
	const listRef = useRef();
	const ref = useRef();
	const isFetching = useRef(false);

	const [showList, setShowList] = useState(false);
	useOutsideClick(ref, () => setShowList(false));

	useInterval(() => {
		dispatch(getCount());
	}, 15000);

	const handleScroll = () => {
		const reachedBottom = (Math.round(listRef.current.scrollTop) + listRef.current.clientHeight) === listRef.current.scrollHeight;

		//isFetching flag used to make sure fetchNotifications() runs only once when scroll reaches bottom
		if (!isFetching.current && reachedBottom && hasNextPage) {
			isFetching.current = true;
			dispatch(fetchNotifications(currentPage + 1));
		}
		if (!reachedBottom) {
			isFetching.current = false;
		}
	};

	const clearNotifications = () => {
		dispatch(clearAllNotifications());
	};

	const playAudio = () => {
		const audio = new Howl({
			src: [sound]
		});
		audio.play();
	};

	useEffect(() => {
		dispatch(fetchNotifications(currentPage));
	}, []);

	useEffect(() => {
		if (hasNewNotification) {
			dispatch(fetchNotifications(currentPage));
			playAudio();
		}
	}, [hasNewNotification]);

	useEffect(() => {
		if (isClearAll) {
			dispatch(reset());
		}
	}, [isClearAll]);

	useEffect(() => {
		if (isItemShown) {
			setShowList(false);
			dispatch(fetchNotifications(currentPage));
		}
	}, [isItemShown]);

	return (
		<div className='notification me-3' ref={ref}>
			<button className='notification__btn' onClick={() => setShowList(!showList)}>
				<img src={bellIcon} alt='Bell Icon' width={16} height={16}/>
				{
					total > 0 && <span className='notification__total'>{total}</span>
				}
			</button>
			<div className={`notification__content  ${showList ? 'show' : ''}`}>
				<div className='notification__overview'>
					<span data-testid='total'>
						Total {total}
					</span>
					{
						total > 0 && <button onClick={clearNotifications} className='btn-clear' data-testid='btn-clear'>Clear all</button>
					}
				</div>
				<ul ref={listRef} onScroll={handleScroll} className='notification__list list-unstyled mb-0' role='notify-list' data-testid='notify-list'>
					{
						loadingClear ? <Loading/> : (
							<>
								{
									notifications.map((notification, index) => <NotificationItem key={index} item={notification}/>)
								}
								{
									loading && <Loading/>
								}
							</>
						)
					}
				</ul>
			</div>
		</div>
	);
};

export default Notification;
