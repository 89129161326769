import React from 'react';
import emptyMessage from 'assets/images/message/empty-messages.png';
import './Style.scss';

function EmptyMessage() {
    return <div className='empty-message'>
        <img src={emptyMessage} alt='empty-message' width='96px;' height='96px' />
        <span className='txt'>No message</span>
    </div>;
}

export default EmptyMessage;
