import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useTime from 'hooks/useTime';
import moment from 'moment';
import Loading from 'components/loading/Loading';
import Countdown from 'components/countdown/Countdown';
import Empty from './Empty';
import './Table.scss';
import { ORDER_STATUSES } from 'constants/orders-status';
import OrderPreview from 'components/order-preview/OrderPreview';

const Table = ({ data, status, type }) => {
	const navigate = useNavigate();
	const loading = useSelector((state) => state.orders.loading);
	const { getTimeDiff, getUrgency } = useTime();
	const rowRef = useRef([]);

	const handleRowClick = (e, order, isAvaible, idx) => {
		e.preventDefault();
		if (e.ctrlKey) {
			window.open(`/details/${order.id}`, '_blank');
		} else if (!isAvaible) {
			navigate(`/details/${order.id}`);
		}
		if (isAvaible) {
			const rows = rowRef.current.filter(item => item);

			for (let i = 0; i < rows?.length; i++) {
				if (i !== idx) {
					rowRef.current[i]?.nextElementSibling.classList.add('d-none');
				}
			}
			rowRef.current[idx]?.nextElementSibling.classList.toggle('d-none');
		}
	};

	return (
		<div className='c-table'>
			<div className='table-responsive'>
				<table className='table mb-0'>
					<thead className='thead-dark'>
					<tr>
						<th className='table-dark c-table__id'>#</th>
						<th className='table-dark c-table__id'>Type</th>
						<th className='table-dark c-table__status'>
							Status
						</th>
						<th className='table-dark c-table__size'>Size</th>
						<th className='table-dark c-table__type'>
							Document Type
						</th>
						<th className='table-dark c-table__discipline'>
							Discipline
						</th>
						{type === 'search' ? (
							<>
								<th className='table-dark c-table__urgency'>
									Urgency
								</th>
								<th className='table-dark c-table__deadline'>
									Create at
								</th>
							</>
						) : [ORDER_STATUSES.COMPLETED, ORDER_STATUSES.DISPUTING, ORDER_STATUSES.REFUNDED].includes(status) ? (
							<th className='table-dark c-table__completed text-lg-center'>
								Completed
							</th>
						) : (
							<>
								<th className='table-dark c-table__urgency'>
									Urgency
								</th>
								<th className='table-dark c-table__deadline'>
									Deadline
								</th>
							</>
						)}
					</tr>
					</thead>
					<tbody>
					{loading ? (
						<tr>
								<td colSpan={7}>
									<Loading />
								</td>
							</tr>
						) : !data.length ? (
							<tr>
								<td colSpan={7}>
									<Empty />
								</td>
							</tr>
						) : (
							data.map((order, idx) => {
								const status =
									order.status === ORDER_STATUSES.COMPLETED ||
									order.status === ORDER_STATUSES.CHECKING ||
									order.status === ORDER_STATUSES.REVISION
										? ORDER_STATUSES.COMPLETED
										: order.status;
								const completeAt =
									order.status === ORDER_STATUSES.FINALIZING
										? getTimeDiff(order.updated_at)
										: order.completed_at
											? getTimeDiff(order.completed_at)
											: '~';
								const slideNumber =
									order.slides_number === 0
										? '~'
										: `${order.slides_number} ss.`;
								const papeNumber =
									order.pages_number === 1 ? 'p' : ' pp';

								const isAvaibleStatus = (order.status === ORDER_STATUSES.AVAILABLE && location.pathname.includes(['available']));

								return (<React.Fragment key={idx}>
										<tr
											className='order-info text-decoration-none'
											onClick={(e) => handleRowClick(e, order, isAvaibleStatus, idx)}
											ref={(el) => (rowRef.current[idx] = el)}
										>
											<td className='order-info__id text-primary' data-testid='uid'>
												{order.uid}
											</td>

											<td data-testid='type'>
												<p className={`order-type order-type__${order.type}`}>{order.type}</p>
											</td>

											<td>
											<span
												className={`order-status order-status--${status}`} data-testid='status'
											>
												{status === 'processing' ? 'completed' : status}
											</span>
											</td>
											<td>
											<span className='text-success' data-testid='pages_number'>
												{order.pages_number === 0 ? `~` : `${order.pages_number}${papeNumber}.`}
											</span>
												&nbsp;/&nbsp;
												<span className='text-warning' data-testid='slides_number'>
												{slideNumber}
											</span>
											</td>
											<td>
												<span data-testid='document_type'>{order.document_type}</span>
											</td>
											<td>
												<span data-testid='discipline'>{order.discipline}</span>
											</td>
											{type === 'search' ? (
												<>
													<td>
													<span className='text-success'>
														{getUrgency(
															order.urgency
														)}
													</span>
													</td>
													<td>
													<span data-testid='created_at'>
														{moment(
															order.created_at
														).format('DD MMM YYYY')}
													</span>
													</td>
												</>
											) : [ORDER_STATUSES.COMPLETED, ORDER_STATUSES.FINALIZING, ORDER_STATUSES.REVISION, ORDER_STATUSES.REFUNDED, ORDER_STATUSES.DISPUTING].includes(order.status) ? (
												<td className='text-lg-center'>
												<span className='ps-2' data-testid='completed'>
													{completeAt}
												</span>
												</td>
											) : (
												<>
													<td>
													<span className='text-success' data-testid='urgency'>
														{getUrgency(
															order.urgency
														)}
													</span>
													</td>
													<td>
														<Countdown
															deadline={
																order.deadline
															}
														/>
													</td>
												</>
											)}
										</tr>
										{isAvaibleStatus && <OrderPreview order={order}/>}
									</React.Fragment>
								);
							})
					)}
					</tbody>
				</table>
			</div>
		</div>
	);
};
Table.propTypes = {
	data: PropTypes.array,
	status: PropTypes.string,
	type: PropTypes.string
};

Table.defaultProps = {
	data: [],
	status: '',
	type: ''
};

export default Table;
