export const MESSAGE_STATUS = {
    PENDING: 'pending',
    SUCCESS: 'success',
    ERROR: 'error'
};

export const TYPE_ACTIVE = {
    DASHBOARD: 'list',
    MESSAGE: 'message'
};

export const LIMIT_PER_PAGE = 10;
export const FROM = 'writer';
