import React from 'react';
import iconImage from 'assets/images/message/message-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeTypeSegment,
    setIdMessageSelected,
    toggleMessage, updateMessageInfo
} from 'store/slices/message';
import { getTotalUnreadMessage } from 'store/thunks';
import { useParams } from 'react-router-dom';
import { TYPE_ACTIVE } from 'constants/message';

function MessageIcon() {
    const dispatch = useDispatch();
    const { totalUnread, isShowMessage } = useSelector((state) => state.message);
    const { order: { data } } = useSelector((state) => state.order);
    const { id } = useParams();

    const handleIconClick = () => {
        const orderItem = id ? {
            id: data?.id,
            signature: data?.signature,
            status: data?.status,
            messagable: data?.messagable,
            uid: data?.uid
        } : {};

        const client = data?.client || {};
        //Close message
        if (isShowMessage || !isShowMessage && id) {
            dispatch(updateMessageInfo({ order: orderItem, client }));
        }

        dispatch(changeTypeSegment(id ? TYPE_ACTIVE.MESSAGE : TYPE_ACTIVE.DASHBOARD));
        dispatch(setIdMessageSelected(id));
        dispatch(toggleMessage());
        dispatch(getTotalUnreadMessage());
    };

    return <button className='notification__btn' onClick={handleIconClick}>
       <img alt='MessageIcon' src={iconImage} width={16} height={16} />
        {
            totalUnread > 0 && <span className='notification__total'>{totalUnread > 99 ? '99+' : totalUnread}</span>
        }
    </button>;
}

export default MessageIcon;
