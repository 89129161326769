import React from 'react';
import PropTypes from 'prop-types';
import Files from 'components/files/Files';
import InfoEvent from '../InfoEvent';

const FileUploaded = ({ attaches, type }) => {
	return (
		<InfoEvent
			attaches={attaches}
			type={type}
			layout={
				<div className='activity-info'>
					<Files attaches={attaches} type={type} />
				</div>
			}
		/>
	);
};

FileUploaded.propTypes = {
	attaches: PropTypes.array,
	type: PropTypes.string
};

FileUploaded.defaultProps = {
	attaches: [],
	type: ''
};

export default FileUploaded;
