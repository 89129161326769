import API from '../baseAPI';
import endPoints from '../endpoints';

export const messageService = {
    sendOrderMessage: (orderId, data) => {
        return API.post(`${endPoints.ORDER}/${orderId}/messages/send`, data);
    },
    markMessageAsRead: (orderId) => {
        return API.post(`${endPoints.ORDER}/${orderId}/messages/read`);
    },
    getOrderMessage: ({ page, signal }) => {
        return API.get(`${endPoints.ORDER}/messages?page=${page}`, { signal });
    },
    getOrderMessageWillListen: () => {
        return API.get(`${endPoints.ORDER}/messagable`);
    },
    getTotalUnreadMessage: () => {
        return API.get(`${endPoints.ORDER}/unread`);
    },
    getCursorMessage: (orderId) => {
        return API.get(`${endPoints.ORDER}/${orderId}/messages/cursor`);
    }
};
