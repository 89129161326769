import React from 'react';
import './Style.scss';
import PropTypes from 'prop-types';

function DividerMessage({ unread, time, isFirstElement }) {
    return <div className={`divider-message ${isFirstElement ? 'margin-top-0' : ''} ${unread ? 'unread' : ''}`}>
        <span className='time'>{time}</span>
        {
            unread && <div className='new'>NEW</div>
        }

    </div>;
}

DividerMessage.propTypes = {
    unread: PropTypes.bool,
    isFirstElement: PropTypes.bool,
    time: PropTypes.any.isRequired
};

export default DividerMessage;
