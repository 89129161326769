import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders } from 'store/thunks';
import Overview from 'components/overview/Overview';
import Table from 'components/table/Table';
import Pagination from 'components/pagination/Pagination';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Notify from 'components/notify/Notify';
import { resetErrorMessage } from 'store/slices/order';
import { ERR_MSG } from 'constants/error-message';

const Dashboard = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const notifyRef = useRef();
	const { orders, total, size, currentPage, perPage, loading } = useSelector(state => state.orders);
	const { error } = useSelector(state => state.order);
	const [searchParams] = useSearchParams();
	const page = searchParams.get('page') || 1;

	useEffect(() => {
		if (!loading) {
			dispatch(fetchOrders({ page }));
		}
	}, [page]);

	const handlePageChange = ({ selected }) => {
		navigate(`/?page=${selected + 1}`);
	};

	useEffect(() => {
		if (error && error?.message === ERR_MSG.NOT_ACCESS) {
			notifyRef.current?.show(
				'Access denied!'
			);
			dispatch(resetErrorMessage());
		}
	}, [error]);

	return (
		<>
			<Notify className='text-left mb-4 alert alert-danger' ref={notifyRef}/>
			<Overview/>
			<div className='box-padding box-border bg-white'>
				<Table data={orders?.data}/>
				{total > size && !loading && (
					<Pagination
						total={total}
						limit={perPage}
						currentPage={currentPage}
						handlePageChange={handlePageChange}
						className='d-flex'
					/>
				)}
			</div>
		</>
	);
};

export default Dashboard;
