import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'components/countdown/Countdown';
import { ORDER_STATUSES } from 'constants/orders-status';
import PaperListing from '../paper-listing/PaperListing';
import WriterUploadPaper from '../writer-upload-paper/WriterUploadPaper';
import WriterApplied from '../writer-applied/WriterApplied';
import useTime from 'hooks/useTime';
import './Aside.scss';

const Aside = ({ order, handleShowRequestForm, isShowRequest }) => {
	const { getTimeDiff } = useTime();

	return (
		<div className='aside'>
			{/*<h3 className='fs-6 box-padding mb-0'>Dashboard</h3>*/}
			<>
				{[
					ORDER_STATUSES.PREPARING,
					ORDER_STATUSES.EDITING,
					ORDER_STATUSES.AVAILABLE
				].includes(order?.status) ? (
						<Countdown
							deadline={order?.deadline}
							className={`aside__deadline box-padding  text-white fs-5 fw-600 aside__deadline${
								order?.deadline < 0 ? '--warning' : '--info'
							}`}
						/>
					) : (
						<div className='aside__deadline aside__deadline--info box-padding  text-white fs-5 fw-600'>
							<span>
								{
									order?.status === ORDER_STATUSES.FINALIZING
										? getTimeDiff(order?.updated_at) : order?.completed_at ? getTimeDiff(order?.completed_at) : '~'
								}
							</span>
						</div>
					)}
				{order?.is_assignee_of ? (
					[ORDER_STATUSES.PREPARING, ORDER_STATUSES.EDITING].includes(
						order?.status
					) && (
						<div className='pd-12 aside__upload d-flex justify-content-end'>
							<WriterUploadPaper/>
							{order?.is_prepared && (
								<button
									onClick={handleShowRequestForm}
									className={`${
										isShowRequest ? 'd-none' : ''
									} ms-2 aside__btn btn btn-danger border-0 rounded-3 pointer-event d-flex align-items-center justify-content-center`}
									data-bs-toggle='tooltip'
									title='Finalize'
									data-testid='btn-open-finalizing-form'
								>
									<i
										className='fa fa-sign-language icon--medium'
										aria-hidden='true'
									></i>
								</button>
							)}
						</div>
					)
				) : <WriterApplied order={order}/>}
				{
					order?.papers && <PaperListing papers={order?.papers}/>
				}
			</>
		</div>
	);
};
Aside.propTypes = {
	handleShowRequestForm: PropTypes.func,
	isShowRequest: PropTypes.bool,
	order: PropTypes.object
};
export default Aside;
