export default Object.freeze({
	ORDER: '/orders',
	LOGIN: '/auth/login',
	LOGOUT: '/auth/logout',
	PASSWORD_RESET: '/password/reset',
	PASSWORD_CHECK: '/password/check',
	PASSWORD_CHANGE: '/password/update',
	SCOPE: '/scope',
	NOTIFICATIONS: '/notifications',
	CLEAR_NOTIFICATIONS: '/notifications/clear',
	PASSWORD_UPDATE: '/profile/password',
	PROFILE_UPDATE: '/profile',
	SEARCH: '/search',
	HISTORY: '/history',
	AVATAR_UPDATE: '/profile/avatar',
	APPLIED: '/applied',
	APPLY: '/apply',
	UPLOAD: '/upload',
	FINALIZE: '/finalize'
});
