import { createSlice } from '@reduxjs/toolkit';
import {
	login,
	logout
} from 'store/thunks';

const initialState = {
	token: null,
	loading: false,
	error: '',
	isExpired: false,
	user: null
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		resetToken: (state) => {
			state.token = null;
		},
		setIsExpired: (state, action) => {
			state.isExpired = action.payload;
		},
		resetError: (state) => {
			state.error = '';
		}
	},
	extraReducers: {
		[login?.pending]: (state) => {
			state.loading = true;
		},
		[login?.fulfilled]: (state, action) => {
			state.token = action.payload.token;
			state.loading = false;
			state.error = '';
			state.user = action.payload.user;
		},
		[login?.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[logout?.fulfilled]: (state) => {
			state.token = null;
		}
	}
});

export const { resetToken, setIsExpired, resetError } = authSlice.actions;

export default authSlice.reducer;

