import React from 'react';
import Notification from 'components/notification/Notification';
import Search from 'components/search-input/SearchInput';
import Toolbar from 'components/toolbar/Toolbar';
import MessageIcon from 'components/message/message-icon';
import './TopNav.scss';

const TopNav = () => {
	return (
		<div className='top-nav bg-white'>
			<div className='top-nav__left me-lg-3'>
				<Search/>
			</div>
			<div className='top-nav__right'>
				<MessageIcon/>
				<Notification />
				<Toolbar />
			</div>
		</div>
	);
};

export default TopNav;
