import Layout from 'components/layout/Layout';
import {
	Dashboard,
	NotFound,
	OrderDetails,
	SignIn,
	ForgotPassword,
	ResetPassword,
	Profile,
	Orders,
	Search
} from 'pages';

const routes = [
	{
		path: '/login',
		component: <SignIn />
	},
	{
		path: '/forgot-password',
		component: <ForgotPassword />
	},
	{
		path: '/password/reset',
		component: <ResetPassword />
	},
	{
		path: '/',
		component: <Layout />,
		isPrivate: true,
		children: [
			{
				path: '/',
				component: <Dashboard />,
				isPrivate: true
			},
			{
				path: '/profile',
				component: <Profile />,
				isPrivate: true
			},
			{
				path: '/search',
				component: <Search />,
				isPrivate: true
			},
			{
				path: '/orders/:status',
				component: <Orders />,
				isPrivate: true
			},
			{
				path: '/details/:id',
				component: <OrderDetails />,
				isPrivate: true
			}
		]
	},
	{
		path: '*',
		component: <NotFound />
	}
];

export default routes;
