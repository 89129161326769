import React from 'react';
import PropTypes from 'prop-types';
import useFile from 'hooks/useFile';
import downloadLink from 'utils/downloadLink';
import historyTypes from 'constants/history-types';
import { getfileIcon } from 'hooks/useFileIcon';
import './Files.scss';
import { getFileName } from 'utils/formatText';
import { useSelector } from 'react-redux';

const Files = ({ attaches, type }) => {
	const { token } = useSelector(state => state.auth);
	const { formatBytes, mappingFileData } = useFile();
	const fileData = mappingFileData(attaches);

	const downloadFile = (e, pathLink, pathName) => {
		e.stopPropagation();
		const pathFile = `${process.env.REACT_APP_API_URL}${pathLink}?token=${token}`;
		downloadLink(pathFile, pathName);
	};

	const getDownloadLink = (e, file) => {
		if (file) {
			return downloadFile(e, file.path, file.name);
		}
	};

	const getTotalFile = () => {
		let total = 0;
		fileData.forEach(({ files } = []) => {
			files?.forEach((file) => {
				if (file.path && file.name && file.size) {
					total++;
				}
			});
		});

		return total;
	};

	const renderFile = (file, indexParent, indexChild) => {
		if (file.path && file.name && file.size) {
			return (
				<li className='file-item' key={indexChild}>
					<a className='file-item__link pb-1' title='filename' key={indexParent} onClick={(e) => getDownloadLink(e, file)}>
						<i className={`icon ${getfileIcon(type)}`}></i>
						<label className='file-item__wrap pointer-event'>
							<span className='file-item__name' data-testid='file-name'>{getFileName(file.name, 80)}</span>
							<span className='file-item__size' data-testid='file-size'>{formatBytes(file.size)}</span>
						</label>
					</a>
				</li>
			);
		}
		return '';
	};

	return (
		<>
			{
				!!getTotalFile() && (
					<ul className={`files list-unstyled pb-3 d-inline-block w-100 ${type === historyTypes.PAPER_APPROVED ? 'order-status--preparing py-2' : (type === historyTypes.PAPER_DECLINED ? 'order-status--canceled py-2' : '')}`}>
						{
							fileData.map(({ files = [] }, idx) => {
								return files.map((file, index) => {
									if (file?.files) {
										return '';
									}

									return renderFile(file, index, idx);
								});
							})
						}
					</ul>
				)

			}

		</>
	);
};

Files.propTypes = {
	attaches: PropTypes.array,
	type: PropTypes.string
};

Files.defaultProps = {
	attaches: [],
	type: ''
};

export default Files;
