import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import errMes from 'assets/images/message/warning.svg';
import { MESSAGE_STATUS } from 'constants/message';
import { updateMessageLocal } from 'store/slices/message';
import { sendMessage, sendOrderMessage } from 'store/thunks';
import { useDispatch, useSelector } from 'react-redux';

function ChatGroup(props) {
    const { senderName, senderAvatar, message, showSenderInfo = true, isHighlight, messageStatus } = props;
    const [highLightMessage, setHighlightMessage] = useState(false);
    const dispatch = useDispatch();
    const { messageHighlight: { count } } = useSelector((state) => state.message);
    const { order } = useSelector((state) => state.order);

    useEffect(() => {
        setHighlightMessage(isHighlight);

        const timer = setTimeout(() => {
            setHighlightMessage(false);
        }, 1000 * 10);

        return () => {
            clearTimeout(timer);
        };
    }, [isHighlight, count]);

    const handleRetryMessage = (e) => {
        e.preventDefault();
        dispatch(updateMessageLocal({
            ...message,
            status: MESSAGE_STATUS.PENDING
        }));
        const payload = {
            orderSignature: order?.data.signature,
            message
        };

        dispatch(sendMessage(payload))
            .unwrap()
            .then(({ count }) => {
                dispatch(sendOrderMessage({
                    orderId: order?.data?.id,
                    data: {
                        content: message.content,
                        total: count,
                        timemark: message.created_at
                    }
                }));
            });
    };

    const renderMessageContent = () => {
        const urlRegex = /((http|https):\/\/)?(www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:[0-9]+)?(\/\S*)?/g;
        const emailRegex = /\b[^\s@]+@[^\s@]+\.[^\s@]+\b/;

        return message.content.replace(urlRegex, (match) => {
            // Check if the match is an email address
            if (emailRegex.test(match)) {
                return match;
            }

            // Check if the match is part of an email address
            const emailParts = message.content.match(emailRegex);
            if (emailParts) {
                for (const email of emailParts) {
                    if (email.includes(match)) {
                        return match;
                    }
                }
            }

            const href = match.search(/^http[s]?:\/\//) === -1 ? 'https://' + match : match;
            return `<a class='msg-link' title="${href}" href="${href}" target="_blank">${match.replace(/¶/g, '&')}</a>`;
        });
    };

    return <div className='chat-group'>
        <div className='avatar'>
            {
                showSenderInfo && <img width={40} height={40} src={senderAvatar}/>
            }
        </div>

        <div className='chat'>
            {
                showSenderInfo && <div className='sender-name'>{senderName}</div>
            }

            <div className='chat-list'>
                <div className='chat-item-wrapper'>
                    <div className={`chat-item`}>
                        <div
                            className={`msg ${highLightMessage ? 'highlight' : ''} ${message.from === 'writer' ? 'owner' : ''}`} dangerouslySetInnerHTML={{ __html: renderMessageContent() }} />
                        <div className='time'>{moment(message.created_at).format('hh:mm A')}</div>

                    </div>
                    {
                        messageStatus === MESSAGE_STATUS.ERROR && (
                            <div className='message-error'>
                                <div className='icon-err'>
                                    <img src={errMes} width={16} height={16}/>
                                </div>
                                <div className='txt-err'>
                                    Message not send. Please <span onClick={handleRetryMessage}>try again.</span>
                                </div>
                            </div>
                        )
                    }
                </div>

            </div>
        </div>

    </div>;
}

ChatGroup.propTypes = {
    senderName: PropTypes.string,
    senderAvatar: PropTypes.string,
    message: PropTypes.any,
    showAvatar: PropTypes.bool,
    showSenderInfo: PropTypes.bool,
    isHighlight: PropTypes.bool,
    messageStatus: PropTypes.string
};

export default ChatGroup;
