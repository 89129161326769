import React, { useEffect, useMemo, useState } from 'react';
import Loading from 'components/loading/Loading';
import NotFound from 'pages/404';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderById } from 'store/thunks';
import { useNavigate, useParams } from 'react-router-dom';
import { ERR_MSG } from 'constants/error-message';
import { addToastr } from 'store/slices/toastr';
import { resetErrorMessage, setOrderDetail, setRefresh } from 'store/slices/order';
import FinalizeRequest from './finalize-request/FinalizeRequest';
import OverviewOrder from './overview/Overview';
import OrderHistory from './timeline/history-types/History';
import Aside from './aside/Aside';
import './index.scss';
import OrderExtras from './extras/Extras';

const OrderDetail = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const { order, history, loading, refresh, error, isVisibleFinalizeForm } = useSelector(state => state.order);
	const [isInValidOrder, setIsInValidOrder] = useState(false);
	const [showRequestForm, setShowRequestForm] = useState(false);
	const { hasNewNotification, notifications } = useSelector(state => state.notification);

	const notifyRefresh = useMemo(() => {
		return id && notifications.length > 0 && notifications[0]?.order_id === Number(id);
	}, [hasNewNotification, notifications, id]);

	const errorCode = useMemo(() => {
		if (!error) {
			return null;
		}
		if (error?.message === ERR_MSG.NOT_FOUND) {
			return 404;
		} else if (error?.message === ERR_MSG.NOT_ACCESS) {
			return 403;
		} else if (error?.message === ERR_MSG.BAD_REQUEST) {
			return 400;
		}
		return 200;
	}, [error]);

	useEffect(() => {
		if (isNaN(id) || errorCode === 404) {
			return setIsInValidOrder(true);
		}

		if (errorCode === 403) {
			navigate('/');
			return;
		}

		if (!loading || refresh || notifyRefresh) {
			dispatch(fetchOrderById(id));
		}
	}, [refresh, notifyRefresh, !errorCode, id]);

	useEffect(() => {
		if (error) {
			if (order?.id || order?.data?.id) {
				dispatch(addToastr({ typeToastr: 'error', timeout: 4000, title: 'Oops! Something went wrong.' }));
				dispatch(resetErrorMessage());
				dispatch(setRefresh(true));
			}
		}
	}, [error, order?.id]);

	useEffect(() => {
		dispatch(setOrderDetail({}));
		return () => {
			setIsInValidOrder(false);
			dispatch(resetErrorMessage());
		};
	}, []);

	const handleShowRequestForm = () => {
		setShowRequestForm(true);
		window.scroll({ top: 0, behavior: 'smooth' });
	};

	const onHideForm = () => setShowRequestForm(false);

	return (
		<>
			{
				isInValidOrder ? <NotFound/> : (
					<div className='order-detail d-flex flex-column-reverse flex-lg-row'>
						{
							loading ? <Loading/> : (
								<>
									<div className='order-detail__content me-lg-3'>
										{(showRequestForm && isVisibleFinalizeForm) && <FinalizeRequest papers={order?.data.papers} handleShowForm = {setShowRequestForm} onHideForm={onHideForm}/> }
										{(order?.data?.previous_id || order?.data?.has_abstract_page) ? <OrderExtras order={order?.data}/> : <></>}
										<OverviewOrder order={order?.data}/>

										<OrderHistory history={history} />
									</div>
									<div className='expand box-border bg-white mb-4 mb-lg-0'>
										<div className='header'>
											<p>Dashboard</p>
										</div>

										<div className='content'>
											<Aside order={order?.data} handleShowRequestForm={handleShowRequestForm} isShowRequest={showRequestForm}/>
										</div>
									</div>
								</>
							)
						}
					</div>
				)
			}
		</>

	);
};

export default OrderDetail;
