import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { emailValidator } from 'utils/validator';
import LayoutSignin from 'components/layout-signin/LayoutSignin';
import { checkPassword, changePassword } from 'store/thunks';
import { resetErrorMsg, setIsPasswordChanged } from 'store/slices/user';
import Notify from 'components/notify/Notify';
import { ERR_MSG } from 'constants/error-message';
import { addToastr } from 'store/slices/toastr';

const ResetPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const notifyRef = useRef();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');
	const defaultValues = {
		email: '',
		password: ''
	};
	const { error, isValidPasswordToken, isPasswordChanged, isLoadingPasswordChange, isFinishedCheckingPasswordToken } = useSelector(
		(state) => state.user
	);
	const { register, handleSubmit } = useForm({
		mode: 'onChange',
		defaultValues
	});

	const changePass = (values) => {
		const body = {
			token: token,
			...values
		};
		dispatch(resetErrorMsg());
		dispatch(changePassword(body));
	};

	const checkValidatePasswordToken = (token) => {
		dispatch(checkPassword(token));
	};

	useEffect(() => {
		if (isFinishedCheckingPasswordToken && !isValidPasswordToken) {
			navigate('*');
		}
	}, [isFinishedCheckingPasswordToken]);

	useEffect(() => {
		if (isPasswordChanged) {
			dispatch(addToastr({ typeToastr: 'success', timeout: 4000, title: `Well done! Your password was successfully updated.` }));
			navigate('/login');
			dispatch(setIsPasswordChanged(false));
		}
	}, [isPasswordChanged]);

	useEffect(() => {
		if (token) {
			checkValidatePasswordToken(token);
		}
	}, []);

	useEffect(() => {
		if (error?.message === ERR_MSG.NOT_FOUND) {
			notifyRef.current?.show('Oops! Something went wrong.');
		}
	}, [error]);

	return (
		<>
			{isValidPasswordToken &&
				<LayoutSignin>
					<div className='sign-in__inner'>
						<h2 className='sign-in__ttl fw-light fs-4'>Change Password</h2>
						<form onSubmit={handleSubmit(changePass)}>
							<p className='change-pass__small-title'>
								Let’s create a new password for your account!
							</p>
							<div className='sign-in__input form-group mb-3'>
								<label className='text-normal text-dark mb-2'>
									E-Mail
								</label>
								<input
									{...register('email', emailValidator)}
									placeholder='E-Mail'
									className='form-control'
									data-testid='input-email-reset'
								/>
								<span className='error-msg mt-1 d-block'>
									{error.errors?.email}
								</span>
							</div>
							<div className='sign-in__input form-group mb-3'>
								<label className='text-normal text-dark mb-2'>
									Password
								</label>
								<input
									{...register('password')}
									placeholder='Password'
									className='form-control'
									type='password'
									data-testid='input-pass-reset'
								/>
								<span className='error-msg mt-1 d-block'>
									{error.errors?.password}
								</span>
							</div>
							<div className='sign-in__input form-group mb-3'>
								<label className='text-normal text-dark mb-2'>
									Confirm Password
								</label>
								<input
									{...register('password_confirmation')}
									placeholder='Confirm Password '
									className='form-control'
									type='password'
									data-testid='input-confirm-reset'
								/>
								<span className='error-msg mt-1 d-block'>
									{error.errors?.password_confirmation?.map((message, i) => <p key={i}>{message}</p>)}
								</span>
							</div>
							<div className='sign-in__button'>
								<button className='btn btn-primary pe-3' data-testid='btn-reset-pass'>
									<i className={`fa ${isLoadingPasswordChange ? 'fa-circle-o-notch fa-spin' : 'fa-key'} me-2`} aria-hidden='true'></i>
									Change Password
								</button>
							</div>
						</form>
						<Notify className='text-left mb-4 alert alert-danger' ref={notifyRef} />
					</div>
				</LayoutSignin>
			}
		</>
	);
};
ResetPassword.propTypes = {
	props: PropTypes.any
};
export default ResetPassword;
