import React from 'react';
import PropTypes from "prop-types";
import './Style.scss'

function MessageHeaderIcon({isActive, onClick}) {

	const getColor = () => {
		if(isActive) {
			return '#0A58CA'
		}
		return '#72777A'
	}

	const handleClick = () => {
		if(onClick) onClick();
	}

	return (
		<div className={`header-icon ${isActive ? "active" : ""} ${onClick ? "cursor" : ""}`} onClick={handleClick}>
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H16C20 2 22 4 22 8V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19Z"
					stroke={getColor()} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
					strokeLinejoin="round"/>
				<path d="M15.9965 11H16.0054" stroke={getColor()} strokeWidth="2" strokeLinecap="round"
					  strokeLinejoin="round"/>
				<path d="M11.9955 11H12.0045" stroke={getColor()} strokeWidth="2" strokeLinecap="round"
					  strokeLinejoin="round"/>
				<path d="M7.99451 11H8.00349" stroke={getColor()} strokeWidth="2" strokeLinecap="round"
					  strokeLinejoin="round"/>
			</svg>

		</div>
	)

}

MessageHeaderIcon.propTypes = {
	isActive: PropTypes.bool,
	onClick: PropTypes.func,
}

export default MessageHeaderIcon;