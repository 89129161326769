import React from 'react';
import PropTypes from 'prop-types';
import CropContent from 'components/crop-content/CropContent';
import InfoEvent from '../InfoEvent';

const TopicChanged = ({ attaches, type }) => {
	return (
		<InfoEvent
			attaches={attaches}
			type={type}
			layout={
				<div className='activity-info'>
					{attaches[0].object.values.topic.to && (
						<div className='activity-issue pt-3'>
							<div className='writer-statistic'>
								<CropContent
									contents={
										attaches[0].object.values.topic.to
									}
								/>
							</div>
						</div>
					)}
				</div>
			}
		/>
	);
};

TopicChanged.propTypes = {
	attaches: PropTypes.array,
	type: PropTypes.string
};

TopicChanged.defaultProps = {
	attaches: []
};
export default TopicChanged;
