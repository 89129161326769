import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetErrorMsg, setIsResetSuccessful } from 'store/slices/user';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { emailValidator } from 'utils/validator';
import LayoutSignin from 'components/layout-signin/LayoutSignin';
import './index.scss';
import { resetPassword } from 'store/thunks';
import Notify from 'components/notify/Notify';

const ForgotPassword = () => {
	const notifyRef = useRef();
	const { error, isResetSuccessful, isLoadingReset } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const defaultValues = {
		email: '',
		callback_url: window.location.origin + '/password/reset'
	};

	const {
		register,
		handleSubmit,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		defaultValues
	});

	const resetPass = async (values, callback_url) => {
		dispatch(resetErrorMsg());
		dispatch(resetPassword(values, callback_url));
	};

	useEffect(() => {
		if (isResetSuccessful) {
			notifyRef.current?.show('We have e-mailed your password reset link!');
			dispatch(setIsResetSuccessful(false));
		}
	}, [isResetSuccessful]);

	return (
		<LayoutSignin>
			<div className='sign-in__inner'>
				<h2 className='sign-in__ttl fw-light fs-4'>Reset Password</h2>
				<Notify className='p-4 mb-4' ref={notifyRef} />
				<form onSubmit={handleSubmit(resetPass)}>
					<div className='sign-in__input form-group mb-3'>
						<label className='text-normal text-dark mb-2'>
							E-Mail
						</label>
						<input
							{...register('email', emailValidator)}
							placeholder='E-Mail'
							className='form-control'
							data-testid='input-email'
						/>
						<span className='error-msg mt-1 d-block'>
							{error.email}
						</span>
					</div>
					<div className='sign-in__button'>
						<button
							className='btn btn-primary pe-3'
							title='Send Password Reset Link'
							disabled={!isValid}
							data-testid='send-reset-password'
						>
							<i className={`fa ${isLoadingReset ? 'fa-circle-o-notch fa-spin' : 'fa-key'} me-2`} aria-hidden='true'></i>
							Send Password Reset Link
						</button>
					</div>
				</form>
			</div>
		</LayoutSignin>
	);
};
ForgotPassword.propTypes = {
	props: PropTypes.any
};
export default ForgotPassword;
