import avatars from 'assets/images/avatar';
import { FROM } from 'constants/message';
import moment from 'moment/moment';

export const getSenderName = (from, client) => {
    if (from !== FROM) {
      return client?.name || 'Client';
    }
    return 'You';
};

export const getSenderAvatar = (from, orderId, userAvatar, clientAvatar, token) => {
    if (from !== FROM) {
        if (clientAvatar) {
            return `${process.env.REACT_APP_API_URL}${clientAvatar}?token=${token}`;
        }
        return avatars.defaultAvatar;
    }

    return userAvatar || avatars.avatar1;
};

export const getMessageEventInfo = (attaches) => {
    if (attaches) {
        const { group_signature, order_id, owner_id, owner_name, writer_cursor } = attaches[0].object;
        return { content: writer_cursor?.content || '', group_signature, order_id, owner_id, owner_name, is_owner: true, timemark: writer_cursor?.timemark || new Date() };
    }
    return null;
};

export const checkSameDate = (currentCreatedAt, prevCreatedAt) => {
    if (!currentCreatedAt || !prevCreatedAt) {
        return false;
    }

    return moment(currentCreatedAt).isSame(new Date(prevCreatedAt), 'date');
};
