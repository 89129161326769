export default function useFile() {
	const formatBytes = (bytes, decimal = 1) => {
		const sizes = ['B', 'KB', 'MB'];
		if (bytes === 0) return '0 B';
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return (parseFloat((bytes / Math.pow(1024, i)).toFixed(decimal)) + ' ' + sizes[i]);
	};

	const mappingFileData = (attaches) => {
		const data = attaches.map(({ object = {} }) => {
			const { shipments = [], files: revisionFiles = [], owner = {} } = object;
			let files = [];
			if (shipments.length) {
				files = [...files, ...shipments.map(i => ({ ...i.paper }))];
			} else if (revisionFiles.length) {
				files = [...files, ...revisionFiles.map(i => ({ ...i }))];
			} else if (Object.keys(owner).length) {
				files = [...files, owner];
			} else {
				files = [...files, object];
			}

			return {
				files
			};
		});
		return data;
	};

	return {
		formatBytes,
		mappingFileData
	};
}
