
export default Object.freeze({
	PAPER_UPLOADED: 'paper.uploaded',
	PAPER_APPROVED: 'paper.approved',
	PAPER_DECLINED: 'paper.declined',
	FILE_UPLOADED: 'file.uploaded',
	ORDER_COMPLETED: 'order.completed',
	ORDER_UPDATED: 'order.updated',
	ORDER_FINALIZING: 'order.finalizing',
	FINALIZATION_DECLINED: 'finalization.declined',
	REVISION_REQUESTED: 'revision.requested',
	REVISION_INITIATED: 'revision.initiated',
	WRITER_APPLIED: 'writer.applied',
	WRITER_APPROVED: 'writer.approved',
	WRITER_ASSIGNED: 'writer.assigned',
	TOPIC_CHANGED: 'topic.changed',
	DISPUTE_STARTED: 'dispute.started',
	ORDER_REFUNDED: 'order.refunded',
	MESSAGE: 'chat.started'
});
