import React from 'react';
import { getFileName } from 'utils/formatText';
import PropTypes from 'prop-types';

const PaperListing = ({ papers }) => {
	return (
		<div className='aside__summary'>
			<ul className='aside__papers mb-0 list-unstyled'>
				{papers.map((paper, idx) => (
					<li className='papers-item  mb-3' key={idx}>
						<span className={`order-status order-status--${paper.status}`}>
							{paper.status}
						</span>
						<div className='papers-item__wrap ms-2'>
							<span className='papers-item__name' data-testid='file-name-upload'>
								{getFileName(paper.name, 25)}
							</span>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

PaperListing.propTypes = {
	papers: PropTypes.array
};

PaperListing.defaultProps = {
	papers: []
};

export default PaperListing;
