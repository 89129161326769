import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { updateProfile } from 'store/thunks';
import { useDispatch, useSelector } from 'react-redux';
import Notify from 'components/notify/Notify';
import { setUpdateSuccessProfile } from 'store/slices/user';

function EmailSetting() {
	const notifyRef = useRef();
	const dispatch = useDispatch();
	const { error, updateSuccessProfile, user, isLoadingProfileChange } = useSelector(
		(state) => state.user
	);

	const defaultValues = {
		email: user?.email,
		name: user?.name
	};

	const {
		register,
		handleSubmit
	} = useForm({
		mode: 'onChange',
		defaultValues
	});

	const handleUpdateInfo = async (values) => {
		dispatch(updateProfile(values));
	};

	useEffect(() => {
		if (updateSuccessProfile) {
			notifyRef.current?.show(
				'Your profile name was successfully updated!'
			);
			dispatch(setUpdateSuccessProfile());
		}
	}, [updateSuccessProfile]);

	return (
		<div>
			<Notify className='p-4 mb-4' ref={notifyRef} />
			<form onSubmit={handleSubmit(handleUpdateInfo)}>
				<div className='mb-3'>
					<label className='form-label'>Email address</label>
					<input {...register('email')} className='form-control' data-testid='email'/>
					<span className='error-msg mt-1 d-block'>
						{error.email}
					</span>
				</div>
				<div className='mb-3'>
					<label className='form-label'>Name</label>
					<input {...register('name')} className='form-control' data-testid='name'/>
					<span className='error-msg mt-1 d-block'>{error.name}</span>
				</div>
				<div className='pt-1'>
					<button
						type='submit'
						className='btn btn-primary fs-14 d-flex align-items-center pe-3'
						title='Submit'
						disabled={isLoadingProfileChange}
						data-testid='btn-update-profile'
					>
						<i
							aria-hidden='true'
							className={`fa ${isLoadingProfileChange ? 'fa-circle-o-notch fa-spin' : 'fa-floppy-o  text-white'} fs-14 me-2 icon--small`}
						></i>
						Update
					</button>
				</div>
			</form>
		</div>
	);
}

export default EmailSetting;
