import { createSlice } from '@reduxjs/toolkit';
import { fetchStatusCount } from 'store/thunks';

const initialState = {
	statusCount: {}
};

const statusCount = createSlice({
	name: 'statusCount',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchStatusCount?.pending]: (state) => {},
		[fetchStatusCount?.fulfilled]: (state, action) => {
			state.statusCount = action.payload;
		},
		[fetchStatusCount?.rejected]: (state) => {}
	}
});

export default statusCount.reducer;
