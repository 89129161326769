import database from './config';
import {
    ref,
    get,
    child,
    orderByChild,
    endBefore,
    onChildAdded,
    query,
    limitToLast,
    limitToFirst,
    startAfter
} from 'firebase/database';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { LIMIT_PER_PAGE, MESSAGE_STATUS } from 'constants/message';

const formatData = (firebaseData, orderSignature) => {
    const data = [];

    for (const msgKey in firebaseData) {
        const item = {
            ...firebaseData[msgKey],
            messageId: msgKey,
            status: 'success',
            orderSignature
        };

        data.push(item);
    }

    return [...data].sort((a, b) => a.created_at - b.created_at);
};

export const getPrevMessage = async (orderSignature, time, limit = LIMIT_PER_PAGE) => {
    const firebaseRef = ref(database);

    let queryData = query(
        child(firebaseRef, `${orderSignature}/messages`),
        orderByChild('created_at'),
        limitToLast(limit)
    );

    if (time) {
        queryData = query(
            child(firebaseRef, `${orderSignature}/messages`),
            orderByChild('created_at'),
            endBefore(time - 1, 'created_at'),
            limitToLast(limit)
        );
    }

    return await getDataCommon(queryData, orderSignature);
};

export const getNextMessage = async (orderSignature, time) => {
    const firebaseRef = ref(database);

    let queryData = query(
        child(firebaseRef, `${orderSignature}/messages`),
        orderByChild('created_at'),
        limitToFirst(LIMIT_PER_PAGE)
    );

    if (time) {
        queryData = query(
            child(firebaseRef, `${orderSignature}/messages`),
            orderByChild('created_at'),
            startAfter(time + 1, 'created_at'),
            limitToFirst(LIMIT_PER_PAGE)
        );
    }

   return await getDataCommon(queryData);
};

export const receiveGroupMessage = (orderSignature, lastMessageDate = Date.now(), callback) => {
    const firebaseRef = query(ref(database, `${orderSignature}/messages`),
        orderByChild('created_at'),
        startAfter(lastMessageDate, 'created_at'), limitToLast(1));

  return onChildAdded(firebaseRef, snapshot => {
        const message = {
            ...snapshot.val(),
            messageId: snapshot.key,
            status: MESSAGE_STATUS.SUCCESS
        };

        callback(message);
    });
};

export const sendMessage = async (orderSignature, message) => {
   try {
       const functions = getFunctions();
       const addMessage = httpsCallable(functions, 'send');
       const { data: { messageId, count } } = await addMessage({
           order_signature: orderSignature,
           content: message.content,
           from: message.from,
           created_at: message.created_at
       });

       return {
           orderSignature,
           message: {
               ...message,
               status: MESSAGE_STATUS.SUCCESS,
               messageId
           },
           count
       };
   } catch (e) {
       return {
           orderSignature,
           message: {
                ...message,
               status: MESSAGE_STATUS.ERROR
           },
           count: undefined
       };
   }
};

export const getTotalMessageOfOrder = async (orderSignature) => {
    const firebaseRef = query(ref(database, `${orderSignature}/count`));

    let count = 0;

    await get(firebaseRef).then((snapshot) => {
        count = snapshot.val();
    });

    return count;
};

const getDataCommon = async (query, orderSignature) => {
    let data = [];

    await get(query).then((snapshot) => {
        if (snapshot.exists()) {
            const firebaseData = snapshot.val();
            data = formatData(firebaseData, orderSignature);
        }
    }).catch((error) => {
        console.error(error);
        throw Error('Something went wrong');
    });
    return data;
};
