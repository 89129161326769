import React from 'react';
import PropTypes from 'prop-types';
import InfoEvent from '../InfoEvent';
import { getMessageEventInfo, getSenderAvatar, getSenderName } from 'utils/message';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeTypeSegment, setIdMessageSelected,
    setMessageDetailData, toggleMessage,
    updateStatusHighlight
} from 'store/slices/message';
import { TYPE_ACTIVE } from 'constants/message';
import { selectAvatar } from 'store/slices/user';

const Message = ({ attaches, type }) => {
    const { messageHighlight, isShowMessage } = useSelector((state) => state.message);
    const { order } = useSelector((state) => state.order);
    const userAvatar = useSelector(selectAvatar);
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(changeTypeSegment(TYPE_ACTIVE.MESSAGE));
        dispatch(setIdMessageSelected(order?.data?.id));
        if (!isShowMessage) {
            dispatch(toggleMessage(true));
        }
        dispatch(updateStatusHighlight({ isMessageOrderActivityClicked: true, count: messageHighlight.count + 1, activityTime: getMessageEventInfo(attaches).timemark }));
        if (!messageHighlight.isMessageOrderActivityClicked) {
            dispatch(setMessageDetailData([]));
        }
    };

    return <InfoEvent
        attaches={attaches}
        type={type}
        layout={
            getMessageEventInfo(attaches).content && (
                <div className='activity-info message-history'>
                    <div className='info'>
                        <div className='avatar'>
                            <img width={40} height={40}
                                 src={getSenderAvatar(getMessageEventInfo(attaches).is_owner ? 'writer' : 'client', getMessageEventInfo(attaches).order_id, userAvatar)}/>
                        </div>
                        <div className='content'>
                            <div className='sender-name'>
                                {getSenderName(getMessageEventInfo(attaches).is_owner ? 'writer' : 'client')}
                            </div>
                            <div onClick={onClick} className={`msg-wrapper`}>

                                <div className={`msg ${getMessageEventInfo(attaches).is_owner ? 'owner' : ''}`}>
                                    <p>
                                        {getMessageEventInfo(attaches).content}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
    />;
};

Message.propTypes = {
    attaches: PropTypes.array,
    type: PropTypes.string
};

Message.defaultProps = {
    attaches: [],
    type: ''
};

export default Message;
