import { createSlice } from '@reduxjs/toolkit';
import { fetchOrders, searchOrderByValue } from 'store/thunks';

const initialState = {
	orders: {},
	loading: false,
	total: 0,
	size: 0,
	error: null,
	currentPage: 1,
	perPage: 15
};

const ordersSlice = createSlice({
	name: 'orders',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchOrders?.pending]: (state) => {
			state.loading = true;
		},
		[fetchOrders?.fulfilled]: (state, action) => {
			state.orders.length = 0;
			state.orders = action.payload?.data;
			state.total = action.payload?.data?.meta.total;
			state.size = action.payload?.data?.meta.per_page;
			state.currentPage = action.payload?.data?.meta.current_page;
			state.perPage = action.payload?.data?.meta.per_page;
			state.loading = false;
		},
		[fetchOrders?.rejected]: (state) => {
			state.loading = false;
			state.orders = {};
			state.total = 0;
		},
		// search order by value
		[searchOrderByValue?.pending]: (state) => {
			state.loading = true;
		},
		[searchOrderByValue?.fulfilled]: (state, action) => {
			state.orders = action.payload?.data;
			state.total = action.payload?.data.total;
			state.size = action.payload?.data.per_page;
			state.currentPage = action.payload?.data.current_page;
			state.perPage = action.payload?.data.per_page;
			state.loading = false;
		},
		[searchOrderByValue?.rejected]: (state) => {
			state.loading = false;
		}
	}
});

export default ordersSlice.reducer;
