import { ORDER_STATUSES } from 'constants/orders-status';
import endPoints from 'services/endpoints';
import API from '../baseAPI';

export const orderService = {
	getByPage: (data) => API.get(`${endPoints.ORDER}${data.status ? `/${data.status}` : ''}${data.page ? `?page=${data.page}` : ''}${data.sortOption && !([ORDER_STATUSES.COMPLETED, ORDER_STATUSES.EDITING]).includes(data.status) ? `&sort=${data.sortOption}` : ''}`),
	getById: (id) => API.get(`${endPoints.ORDER}/${id}`),
	getHistoryById: (id) => API.get(`${endPoints.ORDER}/${id}${endPoints.HISTORY}`),
	getAppliedOrderById: (id) => API.get(`${endPoints.ORDER}/${id}${endPoints.APPLIED}`),
	postAppliedOrderById: (body) => API.post(`${endPoints.ORDER}/${body.id}${endPoints.APPLY}`, body),
	getStatusCount: () => API.get(`${endPoints.SCOPE}`),
	searchOrder: (data) => {
		const keyword = data.keyword ? data.keyword : '';
		const page = data.page ? data.page : '';
		const params = {
			search: decodeURIComponent(keyword),
			page: page
		};

		return API.get(endPoints.SEARCH, {
			params: params
		});
	},
	uploadPaper: (body) => {
		const formData = new FormData();
		formData.append('files[0]', body.file);
		return API.post(`${endPoints.ORDER}/${body.id}${endPoints.UPLOAD}`, formData, {
			'Content-Type': 'multipart/form-data'
		});
	},
	postFinalizeOrderById: (body) => API.post(`${endPoints.ORDER}/${body.id}${endPoints.FINALIZE}`, body),
	//Message
};
