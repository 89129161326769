import endPoints from 'services/endpoints';
import API from '../baseAPI';

export const userService = {
	resetPassword: (body) => API.post(`${endPoints.PASSWORD_RESET}`, body),
	checkPassword: (token) => API.get(`${endPoints.PASSWORD_CHECK}${token ? `?token=${token}` : ''}`, token),
	changePassword: (body) => API.put(`${endPoints.PASSWORD_CHANGE}`, body),
	updatePassword: (body) => API.put(`${endPoints.PASSWORD_UPDATE}`, body),
	updateProfile: (body) => API.put(`${endPoints.PROFILE_UPDATE}`, body),
	updateAvatar: (body) => {
		const formData = new FormData();
		formData.append('avatar', body);
		return API.post(`${endPoints.AVATAR_UPDATE}`, formData, {
			'Content-Type': 'multipart/form-data'
		});
	}
};
