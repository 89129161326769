import React, { useEffect } from 'react';
import Logo from 'assets/images/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import './SideNav.scss';
import { fetchStatusCount } from '../../store/thunks';

const SideNav = () => {
	const dispatch = useDispatch();
	const { refresh } = useSelector((state) => state.order);
	const { hasNewNotification } = useSelector((state) => state.notification);
	const { statusCount = [] } = useSelector((state) => state.statusCount);
	const { loading } = useSelector((state) => state.orders);
	const { status } = useParams();
	const location = useLocation();

	useEffect(() => {
		if (refresh || hasNewNotification) {
			dispatch(fetchStatusCount());
		}
	}, [refresh, hasNewNotification]);

	useEffect(() => {
		dispatch(fetchStatusCount());
	}, []);

	return (
		<div className='side-nav'>
			<Link to='/' className='text-decoration-none'>
				<div className='side-nav__logo d-flex align-items-center'>
					<img width='50' height='50' src={Logo} alt='logo'/>
					<h1 className='side-nav__ttl ms-3 fs-18 text-black mb-0'>
						Let&lsquo;s write
					</h1>
				</div>
			</Link>
			<ul className='side-nav__list list-unstyled mb-0'>
				{statusCount.length &&
				statusCount?.map(({ key, count, icon, path }, index) => {
					const activeItem =
						status === key ||
						(
							!(
								location.pathname.includes(['details']) ||
								location.pathname.includes('profile')
							) &&
							!status &&
							key === 'dashboard' &&
							!location.pathname.includes('search')
						);

					return (
						<li
							className={`side-nav__item ${
								activeItem ? 'active' : ''
							} ${
								!activeItem && loading ? 'link-disable' : ''
							}`}
							key={index}
						>
							<Link
								className='side-nav__link d-flex align-items-center'
								to={path}
							>
								<span className='side-nav__icon d-block text-center'>
									<i
										className={`fa ${icon}`}
										aria-hidden='true'
									></i>
								</span>
								<span className='side-nav__name ms-3'>
									{key.charAt(0).toUpperCase() +
										key.slice(1)}
								</span>
								<span
									data-testid={`${key}-count`}
									className={`side-nav__number ${
										count === 0 ? 'd-none' : ''
									}`}
								>
									{count}
								</span>
							</Link>
						</li>
					);
				})
				}
			</ul>
		</div>
	);
};
export default SideNav;
