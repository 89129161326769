import React, { useState, useRef } from 'react';
import './Toolbar.scss';
import useOutsideClick from 'hooks/useOutsideClick';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'store/thunks';
import { selectAvatar, resetUser } from 'store/slices/user';
import avatars from 'assets/images/avatar';

const Toolbar = () => {
	const { user } = useSelector((state) => state.user);
	const userAvatar = useSelector(selectAvatar);
	const dispatch = useDispatch();
	const [showNav, setShowNav] = useState(false);
	const ref = useRef();
	useOutsideClick(ref, () => setShowNav(false));

	const handleLogOut = () => {
		dispatch(logout());
		dispatch(resetUser());
	};

	const addDefaultImg = ev => {
		ev.target.src = avatars.avatar1;
	};

	return (
		<div className='tool-bar pe-lg-3' ref={ref}>
			<button
				className='tool-bar__btn d-flex align-items-center bg-white'
				onClick={() => setShowNav(!showNav)}
			>
				<img
					className='tool-bar__avatar me-1 rounded-circle overflow-hidden'
					src={ userAvatar || avatars.avatar1 }
					alt='avatar'
					onError = { addDefaultImg }
				/>
				<span className='tool-bar__user-name ps-2'>{user?.name}</span>
			</button>
			<ul
				className={`tool-bar__list list-unstyled mb-0 ${
					showNav ? 'show' : ''
				}`}
			>
				<li className='tool-bar__item'>
					<Link
						onClick={() => setShowNav(false)}
						to='/profile'
						className='d-lg-block'
						href=''
					>
						<i className='ti-user me-3'></i>
						<span>Profile</span>
					</Link>
				</li>
				<li className='tool-bar__item'>
					<button
						className='d-lg-block text-start w-100'
						onClick={() => handleLogOut()}
						data-testid='btn-logout'
					>
						<i className='ti-power-off me-3'></i>
						<span>Logout</span>
					</button>
				</li>
			</ul>
		</div>
	);
};

export default Toolbar;
