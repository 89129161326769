import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import EmailSetting from './email-setting';
import PasswordSetting from './password-setting';
import { updateAvatar } from 'store/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { selectAvatar } from 'store/slices/user';
import Loading from 'components/loading/Loading';
import avatars from 'assets/images/avatar';

const Profile = () => {
	const userAvatar = useSelector(selectAvatar);
	const dispatch = useDispatch();
	const { loading, error } = useSelector(state => state.user);

	const handleUpdateAvatar = (e) => {
		dispatch(updateAvatar(e?.target.files[0]));
	};

	return (
		<div className='box-padding bg-white box-border'>
			<h2 className='profile h5 mb-4'>Profile</h2>
			<div className='profile__content d-md-flex'>
				<div className='profile__upload me-0 me-md-4 me-lg-5 mb-5 mb-md-0'>
					<form>
						{loading ? <div className='d-flex align-items-center justify-content-center profile__upload profile__bg h4'><Loading/></div> : <>
							<img className='profile__img' src={userAvatar || avatars.avatar1Large} />
							<label
								className='profile__label pointer-event d-flex align-items-center justify-content-center'
								htmlFor='avatar'
								type='submit'
							>
								<input
									className='profile__input'
									id='avatar'
									type='file'
									name='file'
									onChange={(e) => handleUpdateAvatar(e)}
									accept='image/*'
									data-testid='input_avatar'
								/>
								<i className='fa fa-camera'></i>
							</label>
						</>
						}
						<span className=' mt-1 d-block'>
							{error.avatar?.map((message, i) => <p data-testid='err-message' className='mb-1 error-msg' key={i}>{message}</p>)}
						</span>
					</form>
				</div>
				<div className='profile__form flex-grow-1 pb-4'>
					<EmailSetting />
					<PasswordSetting />
				</div>
			</div>
		</div>
	);
};
Profile.propTypes = {
	user: PropTypes.object
};
export default Profile;
