import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './root-reducer';
import { persistStore } from 'redux-persist';

const moreMiddlewares = [];

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => [
		...getDefaultMiddleware({ serializableCheck: false }),
		...moreMiddlewares
	]
});

const persistor = persistStore(store);

export { store, persistor };
