export const ORDER_STATUSES = {
	AVAILABLE: 'available',
	REQUEST: 'requests',
	PREPARING: 'preparing',
	EDITING: 'editing',
	COMPLETED: 'completed',
	FINALIZING: 'finalizing',
	REVISION: 'revision',
	DISPUTING: 'disputing',
	REFUNDED: 'refunded'
};
