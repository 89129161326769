import React from 'react';
import PropTypes from 'prop-types';
import './Filter.scss';

const Filter = ({ handleSelectChange }) => {
	return (
		<div className='filter d-flex align-items-center'>
			<span className='filter__ttl me-2'>Sort By:</span>
			<select className='form-select filter__select' aria-label='Default select example' onChange={(e) => handleSelectChange(e)}>
				<option value='created_at'>Creation Date</option>
				<option value='urgency'>Urgency</option>
			</select>
		</div>
	);
};

Filter.propTypes = {
	handleSelectChange: PropTypes.func
};

export default Filter;
