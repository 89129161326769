import avatar1 from './avatar-01.png'
import avatar1Large from './avatar-01-large.png'
import avatar2 from './avatar-02.png'
import avatar3 from './avatar-03.png'
import avatar4 from './avatar-04.png'
import avatar5 from './avatar-05.png'
import avatar6 from './avatar-06.png'
import avatar7 from './avatar-07.png'
import avatar8 from './avatar-08.png'
import avatar9 from './avatar-09.png'
import avatar10 from './avatar-10.png'
import defaultAvatar from './default-avatar.svg'

export default {avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9, avatar10, defaultAvatar, avatar1Large}