import React from 'react';
const NotFound = () => {
	return (
		<div className='min-vh-100 d-flex align-items-center justify-content-center'>
			<h2 className='not-found__ttl mb-0 py-2 pe-3 border-end fs-3'>404</h2>
			<p className='not-found__txt mb-0 ps-3 fs-5'> Not Found </p>
		</div>
	);
};

export default NotFound;
