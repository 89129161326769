import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { updatePassword } from 'store/thunks';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { setUpdateSuccessPass } from 'store/slices/user';
import { resetToken } from 'store/slices/auth';

function PasswordSetting() {
	const { error, updateSuccessPass, isLoadingPasswordChange } = useSelector((state) => state.user);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const defaultValues = {
		current_password: ''
	};
	const { register, handleSubmit } = useForm({
		mode: 'onChange',
		defaultValues
	});

	const handleUpdatePassword = async (values) => {
		dispatch(updatePassword(values));
	};

	useEffect(() => {
		if (updateSuccessPass) {
			navigate('/login');
			dispatch(setUpdateSuccessPass(false));
			dispatch(resetToken());
		}
	}, [updateSuccessPass]);

	return (
		<div>
			<form
				className='mt-5'
				onSubmit={handleSubmit(handleUpdatePassword)}
			>
				<div className='mb-3'>
					<label className='form-label'>Current Password:</label>
					<input
						{...register('current_password')}
						className='form-control'
						type='password'
						data-testid='current_password'
					/>
					<span className='error-msg mt-1 d-block'>
						{error.current_password}
					</span>
				</div>
				<div className='mb-3'>
					<label className='form-label'>New Password:</label>
					<input
						{...register('password')}
						className='form-control'
						type='password'
						data-testid='new_password'
					/>
					<span className='error-msg mt-1 d-block'>
						{error.password}
					</span>
				</div>
				<div className='mb-3'>
					<label className='form-label'>Confirm Password:</label>
					<input
						{...register('password_confirmation')}
						className='form-control'
						type='password'
						data-testid='password_confirmation'
					/>
					<span className='error-msg mt-1 d-block'>
						{error.password_confirmation?.map((message, i) => <p key={i}>{message}</p>)}
					</span>
				</div>
				<div className='pt-1'>
					<button
						type='submit'
						className='btn btn-primary fs-14 d-flex align-items-center pe-3'
						title='Change Password'
						disabled={isLoadingPasswordChange}
						data-testid='btn-update-pass'
					>
						<i
							aria-hidden='true'
							className={`fa ${isLoadingPasswordChange ? 'fa-circle-o-notch fa-spin' : ' fa-pencil-square-o text-white'} fs-14 me-2 icon--small`}
						></i>
						Change Password
					</button>
				</div>
			</form>
		</div>
	);
}
PasswordSetting.propTypes = {
	props: PropTypes.any
};
export default PasswordSetting;
