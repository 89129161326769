import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders } from 'store/thunks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Table from 'components/table/Table';
import Filter from 'components/filter/Filter';
import Pagination from 'components/pagination/Pagination';
import { ORDER_STATUSES } from 'constants/orders-status';
import { resetErrorMessage, setRefresh } from 'store/slices/order';
import { addToastr } from 'store/slices/toastr';
import { ERR_MSG } from 'constants/error-message';

const Orders = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { status } = useParams();
	const [searchParams] = useSearchParams();
	const { orders, total, size, currentPage, perPage, loading } = useSelector(state => state.orders);
	const { refresh, error } = useSelector(state => state.order);
	const page = searchParams.get('page') || 1;
	const [sortOption, setSortOption] = useState(searchParams.get('sort') || '');

	useEffect(() => {
		if (!loading) {
			dispatch(fetchOrders({ status, page, sortOption }));
			dispatch(setRefresh(false));
		}
	}, [status, page, sortOption, refresh]);

	useEffect(() => {
		if (error && error?.message === ERR_MSG.BAD_REQUEST) {
			dispatch(addToastr({ typeToastr: 'error', timeout: 4000, title: 'Oops! Something went wrong.' }));
			dispatch(resetErrorMessage());
			dispatch(setRefresh(true));
		}
	}, [error]);

	const handlePageChange = ({ selected }) => {
		pageDirection(selected + 1, sortOption);
	};

	const handleChange = (e) => {
		setSortOption(e.target.value);
		pageDirection(page, e.target.value);
	};

	const pageDirection = (p, s) => {
		navigate(`/orders/${status}?page=${p}${s && !(['completed', 'editing']).includes(status) ? `&sort=${s}` : ''}`);
	};

	return (
		<>
			<div className='box-padding box-border bg-white'>
				<div className='d-md-flex justify-content-between align-items-center mb-md-5 mb-4'>
					<h3 className='fs-5 fw-600 mb-5 mb-md-0'>{`${status && status === ORDER_STATUSES.REQUEST ? 'Your Requests' : (status?.charAt(0).toUpperCase() + status?.slice(1) + ' Orders')}`}</h3>
					{(status !== ORDER_STATUSES.EDITING && status !== ORDER_STATUSES.COMPLETED) && <Filter handleSelectChange={handleChange}/>}
				</div>
				<Table data={orders?.data} status={status}/>
				{total > size && !loading && (
					<Pagination
						total={total}
						limit={perPage}
						currentPage={currentPage}
						handlePageChange={handlePageChange}
						className='d-flex'
					/>
				)}
			</div>
		</>
	);
};

export default Orders;
