import React from 'react';
import PropTypes from 'prop-types';
import './Overview.scss';
import CropContent from 'components/crop-content/CropContent';
import useTime from 'hooks/useTime';
import { ORDER_STATUSES } from 'constants/orders-status';

const OverviewOrder = ({ order }) => {
	const { getUrgency } = useTime();
	const status = order.status === ORDER_STATUSES.COMPLETED || order.status === ORDER_STATUSES.CHECKING || order.status === ORDER_STATUSES.REVISION ? ORDER_STATUSES.COMPLETED : order.status;

	return (
		<div className='overview-order box-border box-padding bg-white'>
			<h2 className='h6 overview-order__ttl mb-4' data-testid='uid'>Order {order.uid}</h2>
			<ul className='overview-order__list list-unstyled'>
				<li className='overview-order-item d-sm-flex'>
					<div className='overview-order-item__dt mb-sm-0 mb-2 fw-600'>Type</div>
					<div className={`order-type order-type__${order.type}`}
						 data-testid='topic'>{order.type}</div>
				</li>
				<li className='overview-order-item d-sm-flex'>
					<div className='overview-order-item__dt mb-sm-0 mb-2 fw-600'>Topic</div>
					<div className={`overview-order-item__dd ${order.topic == null ? 'empty' : ''}`}
						 data-testid='topic'>{order.topic == null ? '~your choice~' : `${order.topic}`}</div>
				</li>
				<li className='overview-order-item d-sm-flex'>
					<div className='overview-order-item__dt mb-sm-0 mb-2 fw-600'>Status</div>
					<div className='overview-order-item__dd'>
						<span className={`order-status order-status--${status}`} data-testid='status'>{status}</span>
					</div>
				</li>
				<li className='overview-order-item d-sm-flex'>
					<div className='overview-order-item__dt mb-sm-0 mb-2 fw-600'>Type</div>
					<div className='overview-order-item__dd' data-testid='document_type'>{order.document_type}</div>
				</li>
				<li className='overview-order-item d-sm-flex'>
					<div className='overview-order-item__dt mb-sm-0 mb-2 fw-600'>Discipline</div>
					<div className='overview-order-item__dd' data-testid='discipline'> {order.discipline}</div>
				</li>
				<li className='overview-order-item d-sm-flex'>
					<div className='overview-order-item__dt mb-sm-0 mb-2 fw-600'>Level</div>
					<div className='overview-order-item__dd' data-testid='level'>{order.level}</div>
				</li>
				<li className='overview-order-item d-sm-flex'>
					<div className='overview-order-item__dt mb-sm-0 mb-2 fw-600'>Citation Style</div>
					<div className='overview-order-item__dd' data-testid='citation_style'>{order.citation_style ?? "Not Applicable"}</div>
				</li>
				<li className='overview-order-item d-sm-flex'>
					<div className='overview-order-item__dt mb-sm-0 mb-2 fw-600'>Spacing</div>
					<div className='overview-order-item__dd'>
						<span className='spacing'
							  data-testid='spacing'>{order.spacing === 2 ? 'Double' : 'Single'}</span>
					</div>
				</li>
				<li className='overview-order-item d-sm-flex'>
					<div className='overview-order-item__dt mb-sm-0 mb-2 fw-600'>Words</div>
					<div className='overview-order-item__dd'
						 data-testid='words'>~ {order.spacing === 2 && order.pages_number ? +order.pages_number * 275 : +order.pages_number * 550}</div>
				</li>
				<li className='overview-order-item d-sm-flex'>
					<div className='overview-order-item__dt mb-sm-0 mb-2 fw-600'>Urgency</div>
					<div className='overview-order-item__dd' data-testid='urgency'>
						{getUrgency(order.urgency)}
					</div>
				</li>
				<li className='overview-order-item d-sm-flex'>
					<div className='overview-order-item__dt mb-sm-0 mb-2 fw-600'>References</div>
					<div className='overview-order-item__dd'
						 data-testid='sources'>{order.sources === 0 && order.skip_sources === 1 ? `~ Writer's choice ~` : `${order.sources}`}</div>
				</li>
				<li className='overview-order-item d-sm-flex'>
					<div className='overview-order-item__dt mb-sm-0 mb-2 fw-600'>Size</div>
					<div className='overview-order-item__dd d-flex'>
						<div className='overview-order-item__dd--success'
							 data-testid='pages_number'>{`${order.pages_number === 0 ? '~' : (order.pages_number + ' ' + (order.pages_number === 1 ? 'page' : 'pages'))}`}</div>
						&nbsp;/&nbsp;
						<div className='overview-order-item__dd--warning'
							 data-testid='slides_number'>{order.slides_number === 0 ? '~' : `${order.slides_number} slides`}</div>
					</div>
				</li>
				<li className='overview-order-item d-sm-flex'>
					<div className='overview-order-item__dt mb-sm-0 mb-2 fw-600'>Instructions</div>
					<div className='overview-order-item__dd'>
						<div className='activity-issue'>
							<div className='writer-statistic'>
								<CropContent contents={order.instructions}/>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
	);
};

OverviewOrder.propTypes = {
	order: PropTypes.object
};

OverviewOrder.defaultProps = {
	order: {}
};

export default OverviewOrder;
