import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import './Search.scss';

const SearchInput = () => {
	const navigate = useNavigate();

	const defaultValues = {
		keyword: ''
	};

	const {
		register,
		handleSubmit,
		resetField,
		setError,
		clearErrors,
		formState: { errors }
	} = useForm({
		mode: 'onSubmit',
		defaultValues
	});

	const searchOrder = async (values) => {
		if (values.keyword) {
			navigate(`/search?search=${encodeURIComponent(values.keyword)}`);
		} else {
			setError('keyword', { type: 'string', message: 'The search condition is empty!' });
		}
		setTimeout(() => clearErrors(), 4000);
	};

	const resetSearchField = (e) => {
		e.preventDefault();
		resetField('keyword');
	};

	return (
		<div className='search-box'>
			<form className='search' onSubmit={handleSubmit(searchOrder)}>
				<div className='form-group search__wrapp round-3'>
					<i className='fa fa-search search__icon' aria-hidden='true'></i>
					<input
						type='text'
						className={`form-control search__input ${errors.keyword ? 'border-danger' : 'border-0'}`}
						placeholder='Search Order By ID...'
						{...register('keyword')}
					/>
				</div>
				{errors.keyword && <span className='tool_tip error-msg d-block'>{errors.keyword?.message}</span>}
			</form>
			<button onClick={(e) => resetSearchField(e)} className='clear-btn'><i className='fa fa fa-times'></i></button>
		</div>
	);
};

export default SearchInput;
