import historyTypes from 'constants/history-types';

export const getfileIcon = (type) => {
	let icon = '';
	switch (type) {
		case historyTypes.PAPER_APPROVED:
			icon = 'fa fa-check';
			break;
		case historyTypes.PAPER_DECLINED:
			icon = 'fa fa-times text-danger';
			break;
		case historyTypes.ORDER_FINALIZING:
			icon = 'fa fa-file-text-o';
			break;
		case historyTypes.PAPER_UPLOADED:
			icon = 'fa fa-file-text-o text-primary';
			break;
		case historyTypes.ORDER_COMPLETED:
		case historyTypes.REVISION_REQUESTED:
			icon = 'fa fa-file-text';
			break;
		default:
			icon = 'fa fa-file-o text-primary';
			break;
	}
	return icon;
};
