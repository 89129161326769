import React, { useEffect, useRef, useState } from 'react';
import ChatGroup from './chat-group';
import DividerMessage from '../divider-message';
import scrollDownIcon from 'assets/images/message/arrow-down.svg';
import { useDispatch, useSelector } from 'react-redux';
import EmptyMessage from '../empty-message';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
	getPrevMessageDetail, getTotalUnreadMessage,
	markMessageAsRead as markMessageAsReadStore
} from 'store/thunks';
import { receiveGroupMessage } from 'firebase-store/query';
import LoadingMessage from '../loading-message';
import {
	changeLoadMore, changeTypeSegment,
	concatMessage,
	listenEventSendMessage, resetState,
	setMessageDetailData, toggleRefresh, updateMessageInfo, updateShowTitleNewMessage,
	updateStatusHighlight, updateTimeMark
} from 'store/slices/message';
import { checkSameDate, getSenderAvatar, getSenderName } from 'utils/message';
import { selectAvatar } from 'store/slices/user';
import { FROM, LIMIT_PER_PAGE, TYPE_ACTIVE } from 'constants/message';

function ChatMessage() {
	const ref = useRef(null);
	const boxMessageRef = useRef(null);
	const [isShowScrollDownBtn, setIsScrollDownBtn] = useState(false);
	const { messageDetailData,
		typeSegment,
		messageHighlight: { isMessageOrderActivityClicked, count, activityTime },
		autoScroll,
		hasLoadMore,
		showTitleNewMessage,
		messageInfo: {
			loading, order, client
		},
		isRefresh
	} = useSelector((state) => state.message);

	const { order: { data: orderDetail } } = useSelector((state) => state.order);
	const { token } = useSelector((state) => state.auth);

	const { id } = useParams();
	const dispatch = useDispatch();
	const userAvatar = useSelector(selectAvatar);
	const messageChildrenRef = useRef([]);

	const [isLoading, setIsLoading] = useState(true);
	const [loadingGetPrevMessage, setLoadingGetPrevMessage] = useState(false);

	const [number, setNumber] = useState(0);

	let timerScroll = null;
	let timer = null;

	const fetchData = () => {
		const orderSignature = order?.signature;
		const hasVerticalScrollbar = checkScrollbarVisibility();

		if (orderSignature) {
			if (!hasVerticalScrollbar) {
				setLoadingGetPrevMessage(true);
				dispatch(getPrevMessageDetail({ orderSignature, time: isRefresh ? new Date().getTime() : messageDetailData?.[0]?.created_at })).unwrap()
					.then(async (data) => {
						dispatch(concatMessage({ data, position: 'before' }));
						dispatch(updateTimeMark());
						dispatch(toggleRefresh());
						if (data.length < LIMIT_PER_PAGE) {
							setLoadingGetPrevMessage(false);
						} else {
							timer = setTimeout(() => {
								setNumber((prev) => ++prev);
							}, 0);
						}
						setIsLoading(false);
					})
					.catch(() => {
						setIsLoading(false);
					}).finally(() => {
					timer = setTimeout(() => {
						const hasVerticalScrollbar = checkScrollbarVisibility();
						if (hasVerticalScrollbar) {
							handleScrollBottom(ref, 'auto');
							setLoadingGetPrevMessage(false);
						}
					}, 0);
				});
			}
		}
	};

	useEffect(() => {
		if (isRefresh) {
			setIsLoading(true);
			dispatch(setMessageDetailData([]));
		}
	}, [isRefresh]);

	const checkScrollbarVisibility = () => {
		if (!boxMessageRef.current || isRefresh) {
			return false;
		}

		const { scrollHeight, clientHeight } = boxMessageRef.current;

		return scrollHeight > clientHeight + 150;
	};

	const scrollToItem = (index, behavior = 'auto') => {
		if (messageChildrenRef.current[index] && boxMessageRef.current) {
			const itemPosition = messageChildrenRef.current[index].getBoundingClientRect();
			const containerPosition = boxMessageRef.current?.getBoundingClientRect();

			const scrollTop = itemPosition.top - containerPosition.top + boxMessageRef.current.scrollTop;

			boxMessageRef.current.scrollTo({
				top: scrollTop - 60,
				behavior
			});
		}
	};

	useEffect(() => {
		if (!loading) {
			fetchData();
		}
	}, [order?.signature, number, isMessageOrderActivityClicked, loading, isRefresh]);

	useEffect(() => {
		if (!isShowScrollDownBtn) {
			handleScrollBottom(ref);
		}
	}, [messageDetailData, isShowScrollDownBtn]);

	useEffect(() => {
		handleScrollBottom(ref, 'auto');
	}, [autoScroll]);

	useEffect(() => {
		dispatch(changeTypeSegment(TYPE_ACTIVE.MESSAGE));
	}, []);

	useEffect(() => {
		if (isMessageOrderActivityClicked) {
			dispatch(changeLoadMore({ prev: true, next: false }));

			if (!isShowScrollDownBtn) {
				setIsScrollDownBtn(true);
			}
		}

		setIsLoading(true);
	}, [isMessageOrderActivityClicked]);

	useEffect(() => {
		const orderSignature = order?.signature;
		let subscribe = null;
		if (orderSignature) {
			const time = new Date().getTime();

			if (typeSegment === TYPE_ACTIVE.MESSAGE) {
				subscribe = receiveGroupMessage(orderSignature, time, (message) => {
					markMessageAsRead(message);
					dispatch(listenEventSendMessage(message));
				});
			}
		}

		return () => {
			if (subscribe) {
				subscribe();
			}
		};
	}, [order?.signature, typeSegment]);

	useEffect(() => {
		if (order.id) {
			dispatch(markMessageAsReadStore({ orderId: order.id })).unwrap().then(() => {
				dispatch(getTotalUnreadMessage());
			});
		}
	}, [order.id]);

	const markMessageAsRead = (message) => {
		if (message.from === FROM) {
			return;
		}
		const orderId = order?.id;
		dispatch(markMessageAsReadStore({ orderId }));
	};

	useEffect(() => {
		const index = messageDetailData.findIndex((i) => i.created_at === activityTime);

		if (index !== -1) {
			scrollToItem(index, 'smooth');
		}
	}, [count]);

	useEffect(() => {
		dispatch(updateTimeMark());
		return () => {
			dispatch(resetState());
			dispatch(updateMessageInfo({
				client: orderDetail?.client,
				loading: false,
				order: {
					id: orderDetail?.id,
					signature: orderDetail?.signature,
					status: orderDetail?.status,
					messagable: orderDetail?.messagable,
					uid: orderDetail?.uid
				}
			}));
			clearTimeout(timerScroll);
			clearTimeout(timer);
		};
	}, []);

	useEffect(() => {
		handleShowScrollDownButton();
	}, [messageDetailData]);

	const handleScrollBottom = (refCurrent, behavior = 'smooth') => {
		if (refCurrent.current) {
			refCurrent.current.scrollIntoView({ block: 'end', behavior });
		}
	};

	const handleLoadPrevMessage = () => {
		setLoadingGetPrevMessage(true);
		const currentMessageId = messageDetailData?.[0]?.messageId;
		dispatch(getPrevMessageDetail({ orderSignature: order?.signature, time: messageDetailData?.[0]?.created_at || new Date().getTime() }))
			.unwrap()
			.then(async (data) => {
				dispatch(concatMessage({ data, position: 'before' }));
				if (data.length < LIMIT_PER_PAGE) {
					dispatch(changeLoadMore({ prev: false }));
				}
				setLoadingGetPrevMessage(false);

				timerScroll = setTimeout(() => {
					const index = [...data, ...messageDetailData].findIndex((i) => i.messageId === currentMessageId);
					scrollToItem(index);
				}, 0);
			}).finally(() => {
			setLoadingGetPrevMessage(false);
		});
	};

	const handleLoadMessage = () => {
		const { scrollTop } = boxMessageRef.current;

		if (hasLoadMore.prev) {
			if (!isLoading && (scrollTop >= 1 && scrollTop <= 50) && !loadingGetPrevMessage) {
				handleLoadPrevMessage();
			}
		}
	};

	const handleScroll = () => {
		handleShowScrollDownButton();
		handleLoadMessage();
	};

	const handleShowScrollDownButton = () => {
		const { scrollTop, clientHeight, scrollHeight } = boxMessageRef.current;
		const isShow = scrollHeight * 0.9 > scrollTop + clientHeight;
		if (!isShow && showTitleNewMessage) {
			dispatch(updateShowTitleNewMessage(false));
		}
		setIsScrollDownBtn(isShow);
	};

	const getGroupTime = (groupTime) => {
		return moment(groupTime).format('MMMM Do');
	};

	const compare = (a, b) => {
		if (a.created_at < b.created_at) {
			return -1;
		}
		if (a.created_at > b.created_at) {
			return 1;
		}
		return 0;
	};

	const checkConditionShowDividerMessage = (message, prevMes) => {
		return !checkSameDate(message.created_at, prevMes?.created_at);
	};

	const renderDividerMessage = (message, currentIndex) => {
		const prevMes = messageDetailData[currentIndex - 1];

		const time = checkSameDate(message.created_at, new Date()) ? 'Today' : getGroupTime(message.created_at);

		if (checkConditionShowDividerMessage(message, prevMes)) {
			return <DividerMessage isFirstElement={currentIndex === 0} unread={false} time={ time}/>;
		}
	};

	const checkHighlightMessage = (messageId) => {
		if (!messageDetailData.length) {
			return false;
		}

		const message = messageDetailData.find((i) => i.created_at === activityTime);

		return message?.messageId === messageId && isMessageOrderActivityClicked;
	};

	const handleRefreshData = () => {
		if (!hasLoadMore.next) {
			handleScrollBottom(ref);
			return;
		}

		dispatch(updateShowTitleNewMessage(false));
		dispatch(changeLoadMore({ prev: true, next: false }));
		dispatch(setMessageDetailData([]));
		dispatch(updateStatusHighlight({ isMessageOrderActivityClicked: false }));
	};

	const renderScrollDownButton = () => {
		if (!isShowScrollDownBtn) return;

		if (showTitleNewMessage) {
			return <div onClick={ handleRefreshData } className='number-latest-message'>New messages</div>;
		}

		return (
			<div className='scroll-down' onClick={ handleRefreshData } >
				<img src={scrollDownIcon} height={24} width={24} alt='scrollDownIcon'/>
			</div>
		);
	};

	const renderContent = () => {
		if (isLoading || loading) {
			return <LoadingMessage/>;
		}

		if (!messageDetailData.length) {
			return <EmptyMessage/>;
		}

		return (
			<div className='group-time-message'>
				{
					[...messageDetailData].sort((a, b) => compare(a, b)).map((message, i) => {
						const hasDividerMessage = checkConditionShowDividerMessage(message, messageDetailData[i - 1]);

						return (
							<React.Fragment key={i}>
								{renderDividerMessage(message, i)}

								<div
									ref={(el) => (messageChildrenRef.current[i] = el)}
									id={message.messageId}
									key={message.created_at}
									className={`group-message ${message.status === 'success' && message.from === messageDetailData[i - 1]?.from ? 'same-sender' : ''}`}
								>
									<ChatGroup
										showSenderInfo={hasDividerMessage || message.from !== messageDetailData[i - 1]?.from}
										senderAvatar={getSenderAvatar(message.from, id, userAvatar, client.avatar, token)}
										senderName={getSenderName(message.from, client)} messagesGroup={[message]}
										message={message}
										isHighlight={checkHighlightMessage(message.messageId)}
										messageStatus={message.status}
									/>
								</div>

							</React.Fragment>
						);
					})

				}
			</div>
		);
	};

	return (
		<div className='chat-message' ref={boxMessageRef} onScroll={handleScroll}>
			<div className='box-message'>
				{
					loadingGetPrevMessage && !isLoading && <LoadingMessage/>
				}

				{
					renderContent()
				}
				<div ref={ref}/>
				{renderScrollDownButton()}
			</div>
		</div>
	);
}

export default ChatMessage;
