import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	toastr: [],
	id: 0
};

const toastrSlice = createSlice({
	name: 'toastr',
	initialState,
	reducers: {
		addToastr: (state, action) => {
			state.toastr = [...state.toastr, { ...action.payload, id: state.id }];
		},
		removeToastr: (state, action) => {
			state.toastr = state.toastr.filter(toastr => toastr.id !== action.payload);
		}
	}
});

export const { addToastr, removeToastr } = toastrSlice.actions;

export default toastrSlice.reducer;
