const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailValidMsg = 'The email must be a valid email address!';
const requireErrorMsg = (key) => {
	return `${key} is required.`;
};
const lengthErrorMsg = (key, length, type) => {
	if (type === 'min') {
		return `The ${key} must be at least ${length} characters!`;
	}
	return `The ${key} may not be greater than ${length} characters!`;
};

export const emailValidator = {
	required: requireErrorMsg('Email'),
	pattern: {
		value: emailRegex,
		message: emailValidMsg
	},
	maxLength: {
		value: 50,
		message: lengthErrorMsg('email', 50)
	}
};

export const confirmEmailValidator = (validate = () => {}) => ({
	required: requireErrorMsg('Confirm email'),
	validate,
	pattern: {
		value: emailRegex,
		message: emailValidMsg
	}
});

export const nameValidator = {
	required: requireErrorMsg('Name'),
	minLength: {
		value: 2,
		message: lengthErrorMsg('name', 2, 'min')
	}
};

export const passwordValidator = {
	required: requireErrorMsg('Password'),
	minLength: {
		value: 6,
		message: lengthErrorMsg('password', 6, 'min')
	},
	maxLength: {
		value: 50,
		message: lengthErrorMsg('password', 50)
	}
};

export const newPasswordValidator = {
	required: requireErrorMsg('New Password'),
	minLength: {
		value: 6,
		message: lengthErrorMsg('new password', 6, 'min')
	},
	maxLength: {
		value: 50,
		message: lengthErrorMsg('new password', 50)
	}
};

export const confirmPasswordValidator = (validate = () => {}) => ({
	required: requireErrorMsg('Confirm password'),
	validate,
	minLength: {
		value: 6,
		message: lengthErrorMsg('confirm password', 6, 'min')
	},
	maxLength: {
		value: 50,
		message: lengthErrorMsg('confirm password', 50)
	}
});
