import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { emailValidator, passwordValidator } from 'utils/validator';
import { login } from 'store/thunks';
import LayoutSignin from 'components/layout-signin/LayoutSignin';
import { resetError, setIsExpired } from 'store/slices/auth';
import Notify from 'components/notify/Notify';
import useQueryStringParam from 'hooks/useQueryStringParam';
import { ERR_MSG } from 'constants/error-message';

const SignIn = () => {
	const notifyRef = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { error, loading, token } = useSelector((state) => state.auth);
	const callbackURL = useQueryStringParam('callback-url');
	const defaultValues = {
		email: '',
		password: ''
	};
	const {
		register,
		handleSubmit,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		defaultValues
	});

	const signIn = async (values) => {
		dispatch(login(values));
	};

	useEffect(() => {
		if (token) {
			if (callbackURL) {
				navigate(callbackURL);
				dispatch(setIsExpired(false));
			} else {
				navigate('/');
			}
		}
	}, [token]);

	useEffect(() => {
		if (error && error !== ERR_MSG.NOT_ALLOW) {
			notifyRef.current?.show(error);
			dispatch(resetError());
		}
	}, [error]);

	return (
		<LayoutSignin>
			<div className='sign-in__inner'>
				<h2 className='sign-in__ttl fw-light fs-4'>Login</h2>
				<form onSubmit={handleSubmit(signIn)}>
					<div className='sign-in__input form-group mb-3'>
						<label className='text-normal text-dark mb-2' htmlFor='email'>
							E-Mail
						</label>
						<input
							{...register('email', emailValidator)}
							placeholder='E-Mail'
							className='form-control'
							id='email'
						/>
					</div>
					<div className='sign-in__input form-group mb-3'>
						<label className='text-normal text-dark mb-2' htmlFor='password'>
							Password
						</label>
						<input
							{...register('password', passwordValidator)}
							type='password'
							placeholder='Password'
							className='form-control'
							id='password'
						/>
					</div>
					<Notify
						className='text-center mb-4 alert alert-danger'
						ref={notifyRef}
					/>
					<div className='d-flex justify-content-between align-items-center mb-3'>
						<div className='c-checkbox pointer-event'>
							<input
								className='c-checkbox__input'
								type='checkbox'
								name='remember'
								id='remember'
							/>
							<label
								htmlFor='remember'
								className='c-checkbox__label'
							>
								<span className='peer peer-greed'>
									Remember Me
								</span>
							</label>
						</div>
						<div className='sign-in__button'>
							<button
								className='btn btn-primary pointer-event text-uppercase pe-3'
								title='Login'
								disabled={!isValid || loading}
							>
								<i
									className={`fa ${
										loading
											? 'fa-circle-o-notch fa-spin'
											: 'fa-power-off'
									} me-2`}
									aria-hidden='true'
								></i>
								Login
							</button>
						</div>
					</div>
				</form>
				<Link className='text-decoration-none' to={'/forgot-password'}>
					Forgot Your Password?
				</Link>
			</div>
		</LayoutSignin>
	);
};
SignIn.propTypes = {
	props: PropTypes.any
};
export default SignIn;
