import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from './slices/auth';
import user from './slices/user';
import orders from './slices/orders';
import order from './slices/order';
import network from './slices/network';
import toastr from './slices/toastr';
import statusCount from './slices/statusCount';
import notification from './slices/notifications';
import message from './slices/message';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth'],
	blacklist: ['auth', 'user']
};

const authConfig = {
	key: 'auth',
	storage,
	blacklist: ['error', 'isExpired']
};
const userConfig = {
	key: 'user',
	storage,
	blacklist: [
		'error',
		'loading',
		'isLoadingPasswordChange',
		'isLoadingReset',
		'updateSuccessPass',
		'updateSuccessProfile',
		'isValidPasswordToken',
		'isPasswordChanged',
		'isResetSuccessful',
		'isFinishedCheckingPasswordToken'
	]
};

const rootReducer = combineReducers({
	auth: persistReducer(authConfig, auth),
	orders,
	order,
	network,
	toastr,
	statusCount,
	notification,
	message,
	user: persistReducer(userConfig, user)
});

export default persistReducer(persistConfig, rootReducer);
