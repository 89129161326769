import React from 'react';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import './Pagination.scss';

const Pagination = ({ total, limit, currentPage, handlePageChange, className }) => {
	const [pageCount, setPageCount] = useState(0);

	useEffect(() => {
		setPageCount(Math.ceil(total / limit));
	}, [pageCount]);

	return (
		<div className={`c-pagination ${className}`}>
			<ReactPaginate
				forcePage={currentPage - 1}
				pageCount={pageCount}
				pageRangeDisplayed={2}
				marginPagesDisplayed={1}
				containerClassName='c-pagination__list list-unstyled mb-0'
				pageClassName='c-pagination__item'
				pageLinkClassName='c-pagination__link'
				activeLinkClassName='active'
				previousLabel={<i className='fa fa-angle-left' aria-hidden='true' />}
				previousClassName='c-pagination__item'
				previousLinkClassName='c-pagination__link'
				nextLabel={<i className='fa fa-angle-right' aria-hidden='true' />}
				nextClassName='c-pagination__item'
				nextLinkClassName='c-pagination__link'
				disabledClassName='c-pagination__disabled-btn'
				onPageChange={handlePageChange}/>
		</div>
	);
};

Pagination.propTypes = {
	total: PropTypes.number,
	limit: PropTypes.number,
	currentPage: PropTypes.number,
	handlePageChange: PropTypes.func,
	className: PropTypes.string
};

Pagination.defaultProps = {
	limit: 10,
	className: ''
};
export default Pagination;
