import moment from 'moment';

export default function useTime() {
	const getUrgency = (numOfHours, isShortText) => {
		let urgency = '';
		const days = Math.floor(numOfHours / 24);
		const hours = Math.floor(numOfHours % 24);
		if (numOfHours > 72) {
			if (hours > 0) {
				urgency = `${days}${isShortText ? 'd' : `${days > 1 ? ' days ' : ' day '}`} ${hours}${isShortText ? 'h' : `${hours > 1 ? ' hours ' : ' hour '}`}`;
			} else {
				urgency = `${days}${isShortText ? 'd' : `${days > 1 ? ' days ' : ' day '}`}`;
			}
		} else {
			urgency = `${numOfHours}${isShortText ? 'h' : `${numOfHours > 1 ? ' hours ' : ' hour '}`}`;
		}
		return urgency;
	};
	const getDeadlineTimer = (deadline) => {
		--deadline;
		let timeCountdown = Math.abs(deadline);
		const days = Math.floor((timeCountdown * 1000) / (1000 * 60 * 60 * 24));
		const hours = Math.floor(((timeCountdown * 1000) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor(((timeCountdown * 1000) % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor(((timeCountdown * 1000) % (1000 * 60)) / 1000);
		if (days > 0) {
			if (days < 2) {
				timeCountdown = `${days} ${days > 1 ? 'days' : 'day'} ${hours} ${hours > 1 ? 'hours' : 'hour'}`;
			} else {
				timeCountdown = `${hours >= 12 ? (days + 1) : days} ${days > 1 ? 'days' : 'day'}`;
			}
		} else {
			timeCountdown = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
		}
		return timeCountdown;
	};

	const convertUTCToLocal = (createdDate) => {
		const localTime = moment.utc(createdDate).local().format('MMM DD [at] hh:mm A');
		return localTime;
	};

	const getTimeDiff = (createdDate) => {
		const diffTime = moment() - moment.utc(createdDate).toDate();

		const days = Math.floor(diffTime / (1000 * 60 * 60 * 24));
		const hours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));

		let duration = null;

		if (minutes < 1) {
			duration = 'Just now';
		} else if (days === 0 && hours === 0 && minutes >= 1) {
			duration = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
		} else if (days === 0 && hours >= 1 && hours < 24) {
			duration = `${hours} hour${hours > 1 ? 's' : ''} ago`;
		} else if (days >= 1 && days <= 25) {
			duration = `${days} day${days > 1 ? 's' : ''} ago`;
		} else if (days > 25) {
			duration = moment.utc(createdDate).local().format('DD MMM YYYY');
		}
		return duration;
	};

	return {
		convertUTCToLocal,
		getUrgency,
		getDeadlineTimer,
		getTimeDiff
	};
}
