import React from 'react';
import PropTypes from 'prop-types';
import InfoEvent from '../InfoEvent';

const OrderUpdated = ({ attaches, type }) => {
	return (
		<InfoEvent
			attaches={attaches}
			type={type}
			layout={
				<div className='activity-info'>
					<div className='order-changed'>
						<ul className='order-changed__list mb-0 list-unstyled'>
							{Object.keys(attaches[0].object.values).map(
								(item, idx) => (
									<li
										className='order-changed__item mb-2'
										key={idx}
									>
										<span className='icon fa fa-edit fa-change'></span>
										<strong>{item}&nbsp;</strong>changed
										from &nbsp;
										<span className='order-changed__status  order-changed__status--old px-2' data-testid={`from-${item}`}>
											{
												attaches[0].object.values[item]
													.from
											}
										</span>
										&nbsp;to &nbsp;
										<span className='order-changed__status order-changed__status--new px-2' data-testid={`to-${item}`}>
											{attaches[0].object.values[item].to}
										</span>
									</li>
								)
							)}
						</ul>
					</div>
				</div>
			}
		/>
	);
};

OrderUpdated.propTypes = {
	attaches: PropTypes.array,
	type: PropTypes.string
};

OrderUpdated.defaultProps = {
	attaches: []
};
export default OrderUpdated;
