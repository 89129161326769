import axios from 'axios';
import { store } from 'store/store';
import { resetToken, setIsExpired } from 'store/slices/auth';
import { setNetworkError } from 'store/slices/network';
import { addToastr } from 'store/slices/toastr';
import { ERR_MSG } from 'constants/error-message';

const instance = axios.create({
	timeout: 100000,
	maxContentLength: 10000,
	baseURL: process.env.REACT_APP_API_URL
});

const defaultOptions = {
	headers: {
		accept: 'application/json',
		contentType: 'application/json'
	}
};

const get = (url, options = {}) => instance.get(url, { ...defaultOptions, ...options });
const post = (url, body = {}, options = {}) => instance.post(url, body, { ...defaultOptions, options });
const put = (url, body = {}, options = {}) => instance.put(url, body, { ...defaultOptions, options });

const interceptorHandleRequest = (config) => {
	const state = store.getState();
	const token = state.auth?.token;
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
};

const interceptorHandleResponse = (response) => {
	return response;
};

const handleError = (error) => {
	store.dispatch(setNetworkError(true));
	const { isError } = store.getState()?.network;
	if (isError) {
		if ((!error.response?.status || error.response?.data?.message === ERR_MSG.NOT_ALLOW || error.message === ERR_MSG.NETWORK_ERR) && error.message !== ERR_MSG.CANCELED) {
			store.dispatch(addToastr({ typeToastr: 'error', timeout: 4000, title: 'Oops! Something went wrong.' }));
		}
	}

	// Navigate to /login when token expired
	if (error.response?.status === 401) {
		// do not set token expired on failed login attempt
		if (window.location.pathname !== '/login') {
			store.dispatch(resetToken());
			store.dispatch(setIsExpired(true));
		}
	}

	throw error;
};

instance.interceptors.request.use(interceptorHandleRequest, handleError);
instance.interceptors.response.use(interceptorHandleResponse, handleError);

export default {
	get,
	post,
	put
};
