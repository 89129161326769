import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

const Notify = forwardRef((props, ref) => {
	const { timeout, className } = props;
	const [type, setType] = useState(NOTIFY_TYPES.SUCCESS);
	const [isShown, setIsShown] = useState(false);
	const [notifyMsg, setNotifyMsg] = useState('');
	const notifyCls = type === NOTIFY_TYPES.ERROR ? 'danger' : NOTIFY_TYPES.SUCCESS;

	const show = (message = '', type = NOTIFY_TYPES.SUCCESS) => {
		setType(type);
		setNotifyMsg(message);
		setIsShown(true);
	};

	// using for case disapear Notify without waiting 4s
	const hide = () => {
		setIsShown(false);
		setNotifyMsg('');
	};

	useImperativeHandle(ref, () => ({
		show,
		hide
	}), []);

	useEffect(() => {
		if (isShown) {
			setTimeout(() => {
				hide();
			}, timeout);
		}
	}, [isShown]);

	return isShown && <div className={`mt-3 alert-${notifyCls} ${className}`} role='alert'>{notifyMsg}</div>;
});

const NOTIFY_TYPES = {
	SUCCESS: 'success',
	ERROR: 'error'
};

Notify.displayName = 'Notify';
Notify.propTypes = {
	timeout: PropTypes.number,
	className: PropTypes.string
};
Notify.defaultProps = {
	timeout: 4000,
	className: ''
};

export default Notify;
