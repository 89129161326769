export default Object.freeze({
	'writer.approved': 'Your apply approved',
	'writer.assigned': 'You was assigned',
	'writer.applied': 'You applied this Order',
	'paper.approved': 'Paper approved',
	'paper.uploaded': 'Paper uploaded',
	'paper.declined': 'Paper declined',
	'revision.approved': 'Revision requested',
	'order.frozen': 'Order frozen.',
	'order.unfrozen': 'Order unfrozen.',
	'order.editing': 'Edition required',
	'order.completed': 'Order completed',
	'order.updated': 'Order updated',
	'order.forwarded': 'Order forwarded',
	'paper.confirmed': 'Paper Confirmed',
	'topic.changed': 'Topic changed',
	'paper.rejected': 'Paper rejected',
	'file.approved': 'File approved',
	'finalization.declined': 'Order declined',
	'order.finalizing': 'Order Finalizing',
	'dispute.accepted': 'Dispute Started',
	'order.refunded': 'Order Refunded',
	'revision.initiated': 'Revision Initiated'
});
