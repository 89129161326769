import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const useInterval = (callback, delay, stopOperator = null, dependencies = []) => {
	const savedCallback = useRef();
	const intervalRef = useRef();
	// Saved callback as instance to savedCallback
	// Need this to effect the changes for next render
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		if (dependencies && delay) {
			intervalRef.current = setInterval(() => {
				savedCallback.current();
			}, delay);

			return () => clearInterval(intervalRef.current);
		}
	}, [...dependencies, delay]);

	// stop the countdown when expire time reached 1 day
	useEffect(() => {
		if (stopOperator) {
			clearInterval(intervalRef.current);
		}
	}, [stopOperator]);

	return intervalRef?.current;
};

export default useInterval;

useInterval.propTypes = {
	callback: PropTypes.func, // callback function of interval
	delay: PropTypes.number, // number of interval's delay
	timeLeft: PropTypes.number // number of seconds
};

useInterval.defaultProps = {
	callback: () => {},
	delay: 1000,
	timeLeft: 0
};
