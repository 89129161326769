import CropContent from 'components/crop-content/CropContent';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Files from 'components/files/Files';
import useTime from 'hooks/useTime';
import InfoEvent from '../InfoEvent';

const RevisionInitiated = ({ attaches, type }) => {
    const { getDeadlineTimer } = useTime();

    return (
        <InfoEvent
            attaches={attaches}
            type={type}
            layout={
                <>
                    <div className='activity-info__declined p-3'>
                        <div className='declined-item d-flex align-items-center mb-2'>
                            <i className='icon fa fa-flash'></i>
                            <strong className='declined-item__ttl'>
                                Urgency:
                            </strong>
                            <span className='declined-item__txt px-2'>
								{getDeadlineTimer(
                                    attaches[0].object.order.deadline
                                )}
							</span>
                        </div>
                        <div className='declined-item d-flex align-items-center mb-2'>
                            <i className='icon fa fa-clock-o'></i>
                            <strong className='declined-item__ttl'>
                                Expired At:
                            </strong>
                            <span className='declined-item__txt px-2'>
								{moment()
                                    .add(
                                        attaches[0].object.order.deadline,
                                        'seconds'
                                    )
                                    .format('DD MMM YYYY')}
							</span>
                        </div>
                    </div>
                    <div className='activity-issue pt-3'>
                        <div className='writer-statistic'>
                            <CropContent
                                contents={attaches[0].object.comment}
                            />
                        </div>
                    </div>
                    <Files attaches={attaches} type={type} />
                </>
            }
        />
    );
};
RevisionInitiated.propTypes = {
    attaches: PropTypes.array,
    type: PropTypes.string
};

RevisionInitiated.defaultProps = {
    attaches: [],
    type: ''
};
export default RevisionInitiated;
