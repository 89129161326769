import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { removeToastr } from 'store/slices/toastr';

const ToastrItem = ({ data }) => {
	const [timeRemove, setTimeRemove] = useState(data.timeout || 2500);
	const dispatch = useDispatch();

	useEffect(() => {
		const timer = setTimeout(function () {
			dispatch(removeToastr(data.id));
		}, timeRemove);
		return () => clearTimeout(timer);
	}, [timeRemove]);

	return (
		<div className={`toastr__item round5 toastr__item--${data.typeToastr}`}>
			<div className='toastr__txt'>
				{data.title}
			</div>
			<button onClick={() => setTimeRemove(0) } className='toastr__exit c-icon--small hoverIcon' title='Delete'>
				<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<rect x='7' y='6' width='15.5' height='1.5' rx='0.75' transform='rotate(45 7 6)' fill='#868B90'/>
					<rect x='18' y='7.06055' width='15.5' height='1.5' rx='0.75' transform='rotate(135 18 7.06055)' fill='#868B90'/>
				</svg>
			</button>
		</div>
	);
};

ToastrItem.propTypes = {
	data: PropTypes.object,
	handleDelete: PropTypes.func
};
export default ToastrItem;
