import React from 'react';

const Loading = () => {
	return (
		<div className='loading-spacing text-center w-100'>
			<span className='h1 loading-icon fa fa-circle-o-notch fa-spin' />
		</div>
	);
};

export default Loading;
