import { createSlice } from '@reduxjs/toolkit';
import {
	fetchOrderById,
	handleUploadPaper,
	submitAppliedOrderById,
	submitFinalizeOrderById
} from 'store/thunks';

const initialState = {
	order: {},
	history: {},
	applied: {},
	loading: false,
	refresh: false,
	loadingUpload: false,
	disableUpload: false,
	loadingFinalize: false,
	isVisibleFinalizeForm: true,
	error: null
};

const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		setRefresh: (state, action) => {
			state.refresh = action.payload;
		},
		resetErrorMessage: (state) => {
			state.error = null;
		},
		setOrderApplied: (state, action) => {
			state.applied = action.payload;
		},
		setVisibleForm: (state, action) => {
			state.isVisibleFinalizeForm = action.payload;
		},
		setOrderDetail: (state, action) => {
			state.order = action.payload;
		}
	},
	extraReducers: {
		[fetchOrderById?.pending]: (state) => {
			state.loading = true;
			state.error = null;
		},
		[fetchOrderById?.fulfilled]: (state, action) => {
			state.order = action.payload[0]?.data;
			state.history = action.payload[1]?.data;
			state.loading = false;
		},
		[fetchOrderById?.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[handleUploadPaper?.pending]: (state) => {
			state.loadingUpload = true;
			state.disableUpload = true;
			state.error = null;
		},
		[handleUploadPaper?.fulfilled]: (state) => {
			state.loadingUpload = false;
			state.disableUpload = false;
			state.error = null;
		},
		[handleUploadPaper?.rejected]: (state, action) => {
			state.loadingUpload = false;
			state.disableUpload = false;
			state.error = action.payload;
		},
		[submitAppliedOrderById.pending]: (state) => {
			state.loadingApplied = true;
			state.error = null;
		},
		[submitAppliedOrderById.fulfilled]: (state) => {
			state.loadingApplied = false;
			state.error = null;
		},
		[submitAppliedOrderById.rejected]: (state, action) => {
			state.loadingApplied = false;
			state.error = action.payload;
		},
		[submitFinalizeOrderById.pending]: (state) => {
			state.loadingFinalize = true;
		},
		[submitFinalizeOrderById.fulfilled]: (state) => {
			state.loadingFinalize = false;
		},
		[submitFinalizeOrderById.rejected]: (state, action) => {
			state.loadingFinalize = false;
			state.error = action.payload;
		}
	}
});

export const { setOrderDetail, setRefresh, resetErrorMessage, setOrderApplied, setVisibleForm } = orderSlice.actions;

export default orderSlice.reducer;
