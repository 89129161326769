import React from 'react';
import PropTypes from 'prop-types';

const Item = ({ status, count }) => {
	return (
		<li className='overview__item'>
			<div className='bg-white overview__bg'>
				<h6 className='fs-14 overview__ttl'>{status.charAt(0).toUpperCase() + status.slice(1)}</h6>
				<div className='text-end'>
					<span className={`overview__bill overview__bill--${status} px-3 d-inline-block`} data-testid={`overview-${status}`}>{count}</span>
				</div>
			</div>
		</li>
	);
};

Item.propTypes = {
	status: PropTypes.string,
	count: PropTypes.number
};

export default Item;
