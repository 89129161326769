import { createSlice } from '@reduxjs/toolkit';

const networkSlice = createSlice({
	name: 'network',
	initialState: {
		isError: false
	},
	reducers: {
		setNetworkError: (state, action) => {
			state.isError = action.payload;
		}
	}
});

export const { setNetworkError } = networkSlice.actions;

export default networkSlice.reducer;
