import React from 'react';
import './Empty.scss';
const Empty = () => {
	return (
		<div className='no-data d-flex bg-info text-white align-items-center fs-5 p-3 flex-wrap'>
			<i className='fa fa-binoculars me-4' aria-hidden='true' />
			Sorry, nothing found! Please, try again later.
		</div>
	);
};

export default Empty;
