import { createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from 'services';

const login = createAsyncThunk(
	'login',
	async (body, { rejectWithValue, dispatch }) => {
		try {
			const res = await authService?.login(body);
			dispatch({
				type: 'user/setUser',
				payload: res
			});
			return res.data;
		} catch (e) {
			return rejectWithValue(e.response.data.message);
		}
	}
);
const logout = createAsyncThunk('logout', async (_, { rejectWithValue }) => {
	try {
		await authService?.logout;
	} catch (error) {
		return rejectWithValue(error.response.data.message);
	}
});

export {
	login,
	logout
};
