import { createAsyncThunk } from '@reduxjs/toolkit';
import { NAV_ITEM_ICONS } from 'constants/sidebar-item';
import { orderService } from 'services';
import { updateMessageInfo } from '../../slices/message';

const fetchOrders = createAsyncThunk('fetchOrders', orderService?.getByPage);

const fetchOrderById = createAsyncThunk(
	'fetchOrderById',
	async (id, { rejectWithValue, dispatch }) => {
		try {
			const res = await Promise.all([
				orderService.getById(id),
				orderService.getHistoryById(id),
				orderService.getAppliedOrderById(id)
			]).then(values => values);

			/*TODO: check why dispatch setOrderApplied here*/
			dispatch({
				type: 'order/setOrderApplied',
				payload: res[2]?.data.data
			});
			dispatch({
				type: 'order/setRefresh',
				payload: false
			});

			return res;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);

const submitAppliedOrderById = createAsyncThunk(
	'submitAppliedOrderById',
	async (body, { rejectWithValue, dispatch }) => {
		try {
			const res = await orderService?.postAppliedOrderById(body);
			dispatch({
				type: 'order/setRefresh',
				payload: true
			});
			return res;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);

const fetchStatusCount = createAsyncThunk('fetchStatusCount', async () => {
	const res = await orderService?.getStatusCount();
	const data = res.data || {};
	return Object.keys(data).map(key => ({
		icon: NAV_ITEM_ICONS.find(nav => nav.key === key)?.icon || '',
		key,
		path: key === 'dashboard' ? '' : 'orders/' + key,
		count: data[key] || 0
	}));
});
const searchOrderByValue = createAsyncThunk('searchOrder', orderService?.searchOrder);
const handleUploadPaper = createAsyncThunk(
	'handleUploadPaper',
	async (body, { rejectWithValue, dispatch }) => {
		try {
			const res = await orderService?.uploadPaper(body);
			dispatch({
				type: 'order/setRefresh',
				payload: true
			});
			return res;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);

const submitFinalizeOrderById = createAsyncThunk(
	'submitFinalizeOrderById',
	async (body, { rejectWithValue, dispatch }) => {
		try {
			const res = await orderService?.postFinalizeOrderById(body);
			dispatch({
				type: 'order/setRefresh',
				payload: true
			});
			dispatch({
				type: 'order/setVisibleForm',
				payload: false
			});
			return res;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);

const getOrderDetail = createAsyncThunk(
	'order/getOrderDetail',
		async (orderId, { rejectWithValue, dispatch }) => {
			try {
				const { data } = await orderService?.getById(orderId);
				return data;
			} catch (e) {
				return rejectWithValue(e.response.data);
			}
		}
);

export {
	fetchOrders,
	fetchOrderById,
	fetchStatusCount,
	submitAppliedOrderById,
	searchOrderByValue,
	handleUploadPaper,
	submitFinalizeOrderById,
	getOrderDetail
};
