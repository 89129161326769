import { createAsyncThunk } from '@reduxjs/toolkit';
import { userService } from 'services';

const updatePassword = createAsyncThunk(
	'updatePassword',
	async (body, { rejectWithValue }) => {
		try {
			const res = await userService?.updatePassword(body);
			return res;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);
const updateProfile = createAsyncThunk(
	'updateProfile',
	async (body, { rejectWithValue, dispatch }) => {
		try {
			const res = await userService?.updateProfile(body);
			dispatch({
				type: 'user/setUser',
				payload: res
			});
			return res;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);
const updateAvatar = createAsyncThunk(
	'updateAvatar',
	async (body, { rejectWithValue }) => {
		try {
			const res = await userService?.updateAvatar(body);
			return res;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);
const resetPassword = createAsyncThunk(
	'resetPassword',
	async (body, { rejectWithValue }) => {
		try {
			const res = await userService?.resetPassword(body);
			return res;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);
const checkPassword = createAsyncThunk(
	'checkPassword',
	async (body, { rejectWithValue }) => {
		try {
			const res = await userService?.checkPassword(body);
			return res;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);
const changePassword = createAsyncThunk(
	'changePassword',
	async (body, { rejectWithValue }) => {
		try {
			const res = await userService?.changePassword(body);
			return res;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);

export {
	resetPassword,
	checkPassword,
	changePassword,
	updatePassword,
	updateProfile,
	updateAvatar
};
