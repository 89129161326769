import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './Extras.scss';

const OrderExtras = ({ order }) => {
	return (
		<div className='extras-order border bg-white my-4 p-3'>
			<h2 className='h6 extras-order__ttl mb-4'>Extras</h2>
			<ul className='list-unstyled m-0'>
				{order.previous_id && (
					<li className='extras-order-item d-sm-flex gap-5'>
						<div className='extras-order-item__dt mb-sm-0 mb-2 fw-600'>Requested writer</div>
						<div className='extras-order-item__dd' data-testid='sources'>
							<Link to={`/details/${order.previous_id}`} className='text-decoration-none'>
								#000{order.previous_id}
							</Link>
						</div>
					</li>
				)}
				{!!order.has_abstract_page && (
					<li className='extras-order-item d-sm-flex gap-5'>
						<div className='mb-sm-0 mb-2 fw-600 text-nowrap'>One-Page Abstract</div>
						<div className='sub-abstract'>Write a summary of the paper to capture its main findings.</div>
					</li>
				)}
			</ul>
		</div>
	);
};

OrderExtras.propTypes = {
	order: PropTypes.object
};

OrderExtras.defaultProps = {
	order: {}
};

export default OrderExtras;
