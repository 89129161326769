import React from 'react';
import PropTypes from 'prop-types';
import useTime from 'hooks/useTime';
import historyTypes from 'constants/history-types';
import {getMessageEventInfo} from "utils/message";

const InfoEvent = ({ attaches, type, layout }) => {


	const { convertUTCToLocal } = useTime();

	const byYou = [
		historyTypes.PAPER_UPLOADED,
		historyTypes.ORDER_FINALIZING,
		historyTypes.WRITER_APPLIED
	];

	const updateAt = [
		historyTypes.FINALIZATION_DECLINED,
		historyTypes.ORDER_COMPLETED
	];

	const ownerUpdate = [
		historyTypes.PAPER_DECLINED,
		historyTypes.WRITER_APPROVED,
		historyTypes.ORDER_REFUNDED
	];

	const date = [
		historyTypes.FILE_UPLOADED,
		historyTypes.ORDER_UPDATED,
		historyTypes.TOPIC_CHANGED
	];

	const getTimeEvent = () => {
		if (date.includes(type)) {
			return convertUTCToLocal(attaches[0]?.object.date);
		}

		if (updateAt.includes(type)) {
			return convertUTCToLocal(attaches[0]?.object.updated_at);
		}

		if (ownerUpdate.includes(type)) {
			return convertUTCToLocal(attaches[0]?.object.owner?.updated_at || attaches[0]?.object?.updated_at);
		}

		if (attaches[0]?.object.created_at) {
			return convertUTCToLocal(attaches[0]?.object.created_at);
		}

		return '';
	};

	const getEventAuthor = () => {
		if (type === historyTypes.MESSAGE) {
			const { is_owner } = getMessageEventInfo(attaches)

			if (is_owner) {
				return 'by you';
			}

			return 'by client';
		}

		return byYou.includes(type) ? 'by you' : 'by service';
	};

	return (
		<>
			<div className='order-history-item__info bg-white box-padding d-flex justify-content-between'>
				<span className='order-history-item__time' data-testid='event-date'>
					{getTimeEvent()}
				</span>
				<span className='order-history-item__by' data-testid='event-author'>
					{getEventAuthor()}
				</span>
			</div>
			<div className='box-padding'>
				<div className='order-history-item__bg bg-white'>
					<h4 className='order-history-item__ttl text-uppercase fs-6 mb-0 fw-bold' data-testid='event-type'>
						{`${
							attaches.length > 1 &&
							[
								historyTypes.FILE_UPLOADED,
								historyTypes.FILE_APPROVED,
								historyTypes.FILE_DECLINED
							].includes(type)
								? type
									.split('.')
									.join(' ')
									.replace('file', 'files')
								: type.split('.').join(' ').replace('writer', 'you')
						}`}
					</h4>
					<div>{layout}</div>
				</div>
			</div>
		</>
	);
};

InfoEvent.propTypes = {
	attaches: PropTypes.array,
	type: PropTypes.string,
	layout: PropTypes.any,
	timeEvent: PropTypes.any
};

InfoEvent.defaultProps = {
	attaches: [],
	type: ''
};

export default InfoEvent;
