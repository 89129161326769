import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Item.scss';
import HistoryTitles from 'constants/history-titles';
import useTime from 'hooks/useTime';
import { showNotification } from 'store/thunks';
import { useDispatch } from 'react-redux';

const Item = ({ item }) => {
	const { getTimeDiff } = useTime();
	const dispatch = useDispatch();

	const showSelectedNotification = (id) => {
		dispatch(showNotification(id));
	};

	return (
		<li className='notification-item mb-2' role='notify-item' data-testid='notify-item'>
			<Link to={`details/${item.order.id}`} onClick={() => showSelectedNotification(item.id)} className='notification-item__link text-uppercase' data-testid='id'>
				<span className={`order-status order-status--${item.order.status} notification-item__pill`} data-testid='order_id'>{`#000${item.order_id}`}</span>
				<span className='notification-item__text' data-testid='historyType'>{HistoryTitles[`${item.type.name}`].toUpperCase()}</span>
				<span className='notification-item__date' data-testid='created_at'>{getTimeDiff(item.created_at)}</span>
			</Link>
		</li>
	);
};

Item.propTypes = {
	item: PropTypes.object
};

export default Item;
