import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
	updatePassword,
	updateProfile,
	updateAvatar,
	resetPassword,
	checkPassword,
	changePassword
} from 'store/thunks';

const initialState = {
	user: null,
	loading: false,
	isLoadingProfileChange: false,
	isLoadingPasswordChange: false,
	isLoadingReset: false,
	error: '',
	updateSuccessPass: false,
	updateSuccessProfile: false,
	isValidPasswordToken: false,
	isPasswordChanged: false,
	isResetSuccessful: false,
	isFinishedCheckingPasswordToken: false
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		resetUser: () => initialState,
		setIsResetSuccessful: (state, action) => {
			state.isResetSuccessful = action.payload;
		},
		setUser: (state, action) => {
			state.user = action.payload?.data?.user;
		},
		setUpdateSuccessPass: (state, action) => {
			state.updateSuccessPass = action.payload;
		},
		setIsPasswordChanged: (state, action) => {
			state.isPasswordChanged = action.payload;
		},
		setIsValidPasswordToken: (state, action) => {
			state.isValidPasswordToken = action.payload;
		},
		resetErrorMsg: (state) => {
			state.error = '';
		},
		setUpdateSuccessProfile: (state) => {
			state.updateSuccessProfile = false;
		}
	},
	extraReducers: {
		[updatePassword?.pending]: (state) => {
			state.isLoadingPasswordChange = true;
		},
		[updatePassword?.fulfilled]: (state) => {
			state.isLoadingPasswordChange = false;
			state.updateSuccessPass = true;
		},
		[updatePassword?.rejected]: (state, action) => {
			state.isLoadingPasswordChange = false;
			state.updateSuccessPass = false;
			state.error = action.payload.errors;
		},
		[updateProfile?.pending]: (state) => {
			state.isLoadingProfileChange = true;
		},
		[updateProfile?.fulfilled]: (state, action) => {
			state.isLoadingProfileChange = false;
			state.updateSuccessProfile = true;
			state.user = action.payload?.data.data;
			state.error = '';
		},
		[updateProfile?.rejected]: (state, action) => {
			state.isLoadingProfileChange = false;
			state.updateSuccessProfile = false;
			state.error = action.payload.errors;
		},
		[updateAvatar?.pending]: (state) => {
			state.loading = true;
		},
		[updateAvatar?.fulfilled]: (state, action) => {
			state.loading = false;
			state.user = action.payload?.data?.data;
			state.error = '';
		},
		[updateAvatar?.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload.errors;
		},
		[resetPassword?.pending]: (state) => {
			state.isLoadingReset = true;
		},
		[resetPassword?.fulfilled]: (state) => {
			state.isResetSuccessful = true;
			state.isLoadingReset = false;
		},
		[resetPassword?.rejected]: (state, action) => {
			state.error = action.payload.errors;
			state.isResetSuccessful = false;
			state.isLoadingReset = false;
		},
		[checkPassword?.pending]: (state) => {},
		[checkPassword?.fulfilled]: (state) => {
			state.isValidPasswordToken = true;
			state.isFinishedCheckingPasswordToken = true;
		},
		[checkPassword?.rejected]: (state) => {
			state.isValidPasswordToken = false;
			state.isFinishedCheckingPasswordToken = true;
		},
		[changePassword?.pending]: (state) => {
			state.isLoadingPasswordChange = true;
		},
		[changePassword?.fulfilled]: (state, action) => {
			state.isPasswordChanged = true;
			state.user = action.payload?.data?.data;
			state.isLoadingPasswordChange = false;
		},
		[changePassword?.rejected]: (state, action) => {
			state.isPasswordChanged = false;
			state.error = action.payload;
			state.isLoadingPasswordChange = false;
		}
	}
});

export const { resetUser, setIsResetSuccessful, setUser, setUpdateSuccessPass, setIsPasswordChanged, resetErrorMsg, setUpdateSuccessProfile } = userSlice.actions;

export const selectAvatar = createSelector(
	[
		state => state.user?.user?.avatar,
		state => state.auth?.token],
	(avatar, token) => {
		if (!avatar) {
			return null;
		}
		return avatar?.includes('gravatar')
			? avatar
			: `${process.env.REACT_APP_API_URL}${avatar}?token=${token}`;
	}
);

export default userSlice.reducer;
