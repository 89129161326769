import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { submitFinalizeOrderById } from 'store/thunks';
import './FinalizeRequest.scss';
import { getFileName } from 'utils/formatText';

const FinalizeRequest = ({ papers, handleShowForm, onHideForm }) => {
	const refCheckBox = useRef();
	const dispatch = useDispatch();
	const { id } = useParams();
	const [paperList, setPaperList] = useState([]);
	const { error, loadingFinalize } = useSelector(state => state.order);

	const defaultValues = {
		comment: ''
	};

	const {
		register,
		handleSubmit
	} = useForm({
		mode: 'onSubmit',
		defaultValues
	});

	const handleCheckboxChange = (data) => {
		const isChecked = paperList.some(item => item === data.id);
		if (isChecked) {
			setPaperList(paperList.filter((item) => item !== data.id));
		} else {
			setPaperList([...new Set([...paperList, data.id])]);
		}
	};

	const handleSubmitOrderFinalize = (values) => {
		const body = {
			id: id,
			comment: values.comment,
			papers: paperList
		};
		dispatch(submitFinalizeOrderById(body));
	};

	useEffect(() => {
		if (error) {
			onHideForm();
		}
	}, [error]);

	return (
		<div className='finalize-request' data-testid='finalize-form'>
			<h3 className='fs-6 finalize-request__ttl mb-0 box-padding box-border box-bg'>Order Finalization Request</h3>
			<div className='finalize-request__content bg-white box-padding box-border border-top-0'>
				{
					papers && <>
						<h4 className='fs-14 font-ubunty mb-3 pb-1'>Please choose the final papers for this order.</h4>
						{
							papers.filter(item => item.status === 'approved').map((paper, idx) => (
								<div className='form-check ms-2' key={idx}>
									<input
										ref={refCheckBox}
										className='form-check-input pointer-event'
										type='checkbox'
										id={paper.id}
										checked={paperList.some(item => item === paper.id)}
										onChange={() => handleCheckboxChange(paper)}
									/>
									<label className='form-check-label pointer-event text-item' htmlFor={paper.id} data-testid={`${paper.id}`}>
										<span className='fw-600 text-item__name'>{getFileName(paper.name, 100)}</span>
									</label>
								</div>
							))
						}
					</>
				}
				<form className='aside__remark pd-12' onSubmit={handleSubmit(handleSubmitOrderFinalize)}>
					<div className='pt-4 ms-3'>
						<textarea
							data-testid='finalizing-message-box'
							rows={4}
							className='w-100 font-ubunty box-border c-textarea'
							placeholder='Please enter here if you have any comments...'
							{...register('comment')}
						/>
					</div>
					<div className='d-flex justify-content-end pt-3'>
						<button onClick={() => handleShowForm(false)} className='btn btn-danger pe-3 font-ubunty text-uppercase fs-14'
							data-bs-toggle='tooltip'
							title='Cancel'>
							<i className='fa fa-close icon--small me-2'></i>Cancel
						</button>
						<button type='submit' className='btn btn-info text-white ms-2 pe-3 font-ubunty text-uppercase fs-14'
							disabled={!paperList.length || loadingFinalize}
							data-bs-toggle='tooltip'
							title='Confirm'
							data-testid='btn-finalize'
						>
							<i
								className={`fa ${
									loadingFinalize
										? 'fa-circle-o-notch fa-spin'
										: 'fa-check icon--small'
								} me-2`}
							></i>Confirm
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};
FinalizeRequest.propTypes = {
	handleShowForm: PropTypes.func,
	onHideForm: PropTypes.func,
	papers: PropTypes.array
};

FinalizeRequest.defaultProps = {
	onHideForm: () => {},
	handleShowForm: () => {},
	papers: {}
};

export default FinalizeRequest;
