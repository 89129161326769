import React from 'react';
import { useSelector } from 'react-redux';
import OverviewItem from './item/Item';
import Loading from 'components/loading/Loading';
import './Overview.scss';

const Overview = () => {
	const { statusCount = [], loading } = useSelector(state => state.statusCount);
	return (
		<ul className='overview list-unstyled'>
			{
				loading ? (
					<Loading/>
				) : (
					statusCount.length && statusCount.filter(x => x.key !== 'requests' && x.key !== 'dashboard')
						.map(({ key, count }, index) => (
							<OverviewItem key={index} status={key} count={count}/>
						))
				)
			}
		</ul>
	);
};

export default Overview;
