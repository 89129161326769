import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setVisibleForm } from 'store/slices/order';
import { handleUploadPaper } from 'store/thunks';

const WriterUploadPaper = () => {
	const { loadingUpload, disableUpload, isVisibleFinalizeForm } = useSelector((state) => state.order);
	const { id } = useParams();
	const dispatch = useDispatch();
	const handleUpload = (e) => {
		const body = {
			id: id,
			file: e?.target.files[0]
		};
		dispatch(handleUploadPaper(body));
		if (isVisibleFinalizeForm) {
			dispatch(setVisibleForm(false));
		}
	};
	return (
		<>
			<label
				className='aside__btn border-0 rounded-3 btn-success pointer-event aside__upload__btn d-flex align-items-center justify-content-center'
				type='submit'
			>
				<i
					className={`fa fa-${
						loadingUpload
							? 'circle-o-notch fa-spin loading-icon'
							: 'upload'
					} icon--medium`}
					aria-hidden='true'
					data-testid='icon-upload'
				></i>
				<input
					data-testid='btn-upload-paper'
					className='aside__upload__input pointer-event'
					data-bs-toggle='tooltip'
					title='Upload Paper'
					type='file'
					disabled={disableUpload}
					onChange={(e) => handleUpload(e)}
				/>
			</label>
		</>
	);
};

export default WriterUploadPaper;
