import React, { useEffect, useState } from 'react';
import Avatar from 'assets/images/avatar';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { changeTypeSegment, decrementTotalUnread, setIdMessageSelected, updateMessageInfo } from 'store/slices/message';
import { useNavigate, useParams } from 'react-router-dom';
import { FROM, TYPE_ACTIVE } from 'constants/message';
import moment from 'moment';
import { checkSameDate } from 'utils/message';
import { fetchOrderById } from 'store/thunks';
import iconRedirect from 'assets/images/message/info.svg';

function MessageItem(props) {
    const { unread, uid, message, id, orderStatus, totalUnread, messagable, client, signature } = props;
    const { id: orderId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [timeMessage, setTimeMessage] = useState('');
    const { token } = useSelector((state) => state.auth);

    useEffect(() => {
        const ONE_MINUTE = 1000 * 60;
        const time = formatTimeMessage();
        setTimeMessage((time));
        const timer = setInterval(() => {
            setTimeMessage(formatTimeMessage());
        }, ONE_MINUTE);

        return () => {
            clearInterval(timer);
        };
    }, [message]);

    const handleMessageItemClick = () => {
        dispatch(decrementTotalUnread(id));
        dispatch(changeTypeSegment(TYPE_ACTIVE.MESSAGE));
        dispatch(setIdMessageSelected(id));
        dispatch(updateMessageInfo({
            order: {
                id, signature, status: orderStatus, messagable, uid
            },
            client
        }));
    };

    const getAvatar = () => {
        if (message.from === FROM) {
            return Avatar.avatar1;
        }

        if (client?.avatar) {
            return `${process.env.REACT_APP_API_URL}${client.avatar}?token=${token}`;
        }

        return Avatar.defaultAvatar;
    };

    const convertMinutesToSeconds = (minutes) => {
        return 1000 * 60 * minutes;
    };

    const handleRedirect = (e) => {
        e.stopPropagation();
        if (orderId !== `${id}`) {
            navigate(`/details/${id}`);
            if (orderId) {
                dispatch(fetchOrderById(id));
            }
        }
    };

    const formatTimeMessage = () => {
        const createdAt = message.created_at;

        const isSameDate = checkSameDate(new Date(), createdAt);
        const now = new Date().getTime();

        if (isSameDate) {
            if (now - createdAt <= convertMinutesToSeconds(1)) {
                return 'JUST NOW';
            }

            if (now - createdAt > convertMinutesToSeconds(1) && now - createdAt < convertMinutesToSeconds(59)) {
                return moment(createdAt).startOf('seconds').fromNow();
            }

            return moment(createdAt).format('hh:mm A');
        }

        return moment(createdAt).format('MMMM Do');
    };

    return <div className='message-item' onClick={handleMessageItemClick} >
        <div className='avatar'>
            <img src={getAvatar()} alt='avatar' width={40} height={40} />
            {
                !!(message.from !== FROM && totalUnread > 0) && <div className='badge'>{totalUnread}</div>
            }

        </div>
        <div className='content'>
            <div className='group-uid'>
                <div className={`uid uid--${orderStatus}`}>{uid}</div>
                <div className='icon-redirect' onClick={handleRedirect}>
                    <img src={ iconRedirect } width={16} height={16} alt='Icon Redirect'/>
                </div>
            </div>
            <div className='msg'>
                <div className={`txt ${unread ? 'txt--unread' : ''}`}>
                    {message.content}
                </div>
                <div className='time'>{timeMessage}</div>
            </div>
        </div>
    </div>;
}

MessageItem.propTypes = {
    unread: PropTypes.bool,
    message: PropTypes.any,
    uid: PropTypes.string,
    id: PropTypes.any,
    orderStatus: PropTypes.string,
    totalUnread: PropTypes.number,
    messagable: PropTypes.bool,
    client: PropTypes.any,
    signature: PropTypes.string
};

export default MessageItem;
