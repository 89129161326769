import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useTime from 'hooks/useTime';
import useInterval from 'hooks/useInterval';
const oneDayToSeconds = 24 * 60 * 60;

const Countdown = ({ deadline, className }) => {
	const { getDeadlineTimer } = useTime();
	const [countdown, setCountdown] = useState(deadline || 0);
	const isStopCountDown = (-1 * countdown) >= oneDayToSeconds;

	const interval = useInterval(() => setCountdown(countdown - 1), 1000, isStopCountDown, [
		deadline && ((deadline <= oneDayToSeconds) || deadline < 0)
	]);

	useEffect(() => {
		return () => {
			interval && clearInterval(interval);
		};
	}, [interval]);

	return <div className={`fw-bold ${countdown && countdown >= 0 ? 'text-success' : 'text-danger'} ${className}`} data-testid='deadline'>{getDeadlineTimer(countdown)}</div>;
};

Countdown.propTypes = {
	deadline: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	className: PropTypes.string
};

Countdown.defaultProps = {
	className: '',
	deadline: 0
};

export default Countdown;
