import SideNav from 'components/side-nav/SideNav';
import TopNav from 'components/top-nav/TopNav';
import React from 'react';
const Header = () => {
	return (
		<div className='l-header'>
			<TopNav/>
			<SideNav/>
		</div>
	);
};

export default Header;
