import React from 'react';
import PropTypes from 'prop-types';
import CropContent from 'components/crop-content/CropContent';
import InfoEvent from '../InfoEvent';

const WriterApproved = ({ attaches, type }) => {
	return (
		<InfoEvent
			attaches={attaches}
			type={type}
			layout={
				attaches[0].object.message && (
					<div className='activity-info'>
						<div className='activity-issue pt-3'>
							<div className='writer-statistic'>
								<h6 className='fs-6 fw-600'>Writing Service</h6>
								<CropContent
									contents={attaches[0].object.message}
								/>
							</div>
						</div>
					</div>
				)
			}
		/>
	);
};

WriterApproved.propTypes = {
	attaches: PropTypes.array,
	type: PropTypes.string
};

WriterApproved.defaultProps = {
	attaches: []
};

export default WriterApproved;
