import { createSlice } from '@reduxjs/toolkit';
import { fetchNotifications, clearAllNotifications, getCount, showNotification } from 'store/thunks';

const initialState = {
	notifications: [],
	hasNewNotification: false,
	total: 0,
	error: null,
	currentPage: 1,
	hasNextPage: false,
	loading: false,
	isClearAll: false,
	loadingClear: false,
	isItemShown: false
};

const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		reset: () => initialState
	},
	extraReducers: {
		[fetchNotifications?.pending]: (state) => {
			state.loading = true;
		},
		[fetchNotifications?.fulfilled]: (state, action) => {
			// fetch 1st page if new notification comes or a notification is shown
			state.notifications = (state.hasNewNotification || state.isItemShown) ? [...action.payload?.data.data] : [...state.notifications, ...action.payload?.data.data];
			state.currentPage = action.payload?.data.current_page;
			state.hasNextPage = !!action.payload?.data.next_page_url;
			state.total = action.payload?.data.total;
			state.loading = false;
			state.hasNewNotification = false;
			state.isItemShown = false;
		},
		[fetchNotifications?.rejected]: (state) => {
			state.loading = false;
		},
		[getCount?.pending]: () => {
		},
		[getCount?.fulfilled]: (state, action) => {
			if (action.payload > state.total) {
				state.currentPage = 1;
				state.hasNewNotification = true;
			}
		},
		[getCount?.rejected]: () => {
		},
		[clearAllNotifications?.pending]: (state) => {
			state.loadingClear = true;
		},
		[clearAllNotifications?.fulfilled]: (state) => {
			state.isClearAll = true;
			state.loadingClear = false;
		},
		[clearAllNotifications?.rejected]: (state, action) => {
			state.loadingClear = false;
			state.error = action.payload;
		},
		[showNotification?.pending]: (state) => {},
		[showNotification?.fulfilled]: (state, action) => {
			state.isItemShown = true;
			state.currentPage = 1;
		},
		[showNotification?.rejected]: (state) => {}
	}
});

export const { reset } = notificationSlice.actions;

export default notificationSlice.reducer;
