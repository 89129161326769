import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchOrderByValue } from 'store/thunks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Table from 'components/table/Table';
import Pagination from 'components/pagination/Pagination';

const Search = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { orders, total, size, currentPage, perPage, loading } = useSelector(state => state.orders);
	const page = searchParams.get('page');
	const keyword = encodeURIComponent(searchParams.get('search'));

	useEffect(() => {
		if (!loading) {
			dispatch(searchOrderByValue({ keyword, page }));
		}
	}, [page, keyword]);

	const handlePageChange = ({ selected }) => {
		navigate(`/search?search=${keyword}&page=${selected + 1}`);
	};

	return (
		<>
			<div className='box-padding box-border bg-white'>
				<div className='d-md-flex justify-content-between align-items-center mb-md-5 mb-4'>
					<h3 className='fs-5 fw-600 mb-5 mb-md-0'>Search Results</h3>
				</div>
				<Table data={orders?.data} type={'search'}/>
				{total > size && !loading && (
					<Pagination
						total={total}
						limit={perPage}
						currentPage={currentPage}
						handlePageChange={handlePageChange}
						className='d-flex'
					/>
				)}
			</div>
		</>
	);
};

export default Search;
