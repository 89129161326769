export const NAV_ITEM_ICONS = [
  {
    key: "dashboard",
    icon: "fa-home",
  },
  {
    key: "available",
    icon: "fa-star-o",
  },
  {
    key: "requests",
    icon: "fa-check-circle-o",
  },
  {
    key: "preparing",
    icon: "fa-heartbeat",
  },
  {
    key: "editing",
    icon: "fa-wrench",
  },
  {
    key: "disputing",
    icon: "fa-refresh",
  },
  {
    key: "refunded",
    icon: "fa-usd",
  },
  {
    key: "completed",
    icon: "fa-history",
  },
];
