import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificationService } from 'services';

const fetchNotifications = createAsyncThunk(
	'showNotifetchNotificationsfication',
	async (_, { rejectWithValue }) => {
		try {
			const res = await notificationService?.getByPage();
			return res;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);

const clearAllNotifications = createAsyncThunk(
	'clearNotifications',
	async (_, { rejectWithValue }) => {
		try {
			const res = await notificationService?.clearAll();
			return res;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);

const getCount = createAsyncThunk('checkNewNotification', async () => {
	const res = await notificationService?.getCount();
	return res.data.total;
});

const showNotification = createAsyncThunk(
	'showNotification',
	async (id, { rejectWithValue }) => {
		try {
			const res = await notificationService?.show(id);
			return res.data.data.id;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);

export {
	fetchNotifications,
	clearAllNotifications,
	getCount,
	showNotification
};
