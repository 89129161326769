import React from 'react';
import PropTypes from 'prop-types';
import ToastrItem from './item/Item';
import './Toastr.scss';

const Toastr = ({ toastr }) => {
	return (
		<div className='toastr'>
			{
				(toastr || []).map((item, index) => <ToastrItem data={item} key={index}/>)
			}
		</div>
	);
};
Toastr.propTypes = {
	toastr: PropTypes.array
};
export default Toastr;
